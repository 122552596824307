import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import * as M from "materialize-css/dist/js/materialize";

@Component({
	selector: 'unos-izmjena-stip',
	templateUrl: './unos-izmjena-stip.component.html',
	styleUrls: ['./unos-izmjena-stip.component.css']
})
export class UnosIzmjenaStipComponent implements OnInit {

	constructor(private http: HttpClient, public globalSrvc: GlobalService) { }

	@Input() id;
	@Input() novi;

	@Output() onInsertStip = new EventEmitter<any>();
	@Output() onCancel = new EventEmitter<any>();

	prijavastip : any = {id: '', idKategorije : '', idTipa : '', statusPrijave: '', kreditPretGodine: '', drzavljaninCG: '', drzava: '', studiraCG: '', drzavaStud: '', jmbg: '', ime: '', prezime: '', 
	imeRoditelja: '', datumRodjenja: '', pol: '', opstina: '', mjestoPrebivalista: '', ulicaBroj: '', brojPoste: '', telefon: '', brojLK: '', mjestoLK: '', 
	idFakulteta: '', nazivFakulteta: '', nazivStudProg: '', nivoStudija: '', godina: '', indexUspjeha: '', brojIndeksa: '', redniBrojUpisa : '', napomena : ''};
	preporuka : any = null;

	err_kreditPretGodine = false;
	err_kreditPretGodine_poruka = '';
	err_drzavljaninCG = false;
	err_drzavljaninCG_poruka = '';
	err_drzava = false;
	err_drzava_poruka = '';
	err_studiraCG = false;
	err_studiraCG_poruka = '';
	err_drzavaStud = false;
	err_drzavaStud_poruka = '';
	err_jmbg = false;
	err_jmbg_poruka = '';
	err_ime = false;
	err_ime_poruka = '';
	err_prezime = false;
	err_prezime_poruka = '';	
	err_imeOca = false;
	err_imeOca_poruka = '';
	err_imeMajke = false;
	err_imeMajke_poruka = '';
	err_datumRodjenja = false;
	err_datumRodjenja_poruka = '';
	err_pol = false;
	err_pol_poruka = '';
	err_opstina = false;
	err_opstina_poruka = '';
	err_mjestoPrebivalista = false;
	err_mjestoPrebivalista_poruka = '';
	err_ulicaBroj = false;
	err_ulicaBroj_poruka = '';
	err_brojPoste = false;
	err_brojPoste_poruka = '';
	err_telefon = false;
	err_telefon_poruka = '';
	err_brojLK = false;
	err_brojLK_poruka = '';
	err_mjestoLK = false;
	err_mjestoLK_poruka = '';
	err_idFakulteta = false;
	err_idFakulteta_poruka = '';
	err_nazivFakulteta = false;
	err_nazivFakulteta_poruka = '';
	err_nazivStudProg = false; 
	err_nazivStudProg_poruka = '';
	err_nivoStudija = false; 
	err_nivoStudija_poruka = '';
	err_godina = false; 
	err_godina_poruka = '';
	err_indexUspjeha = false; 
	err_indexUspjeha_poruka = '';
	err_brojIndeksa = false; 
	err_brojIndeksa_poruka = '';
	err_redniBrojUpisa = false; 
	err_redniBrojUpisa_poruka = '';
	err_kategorija = false; 
	err_kategorija_poruka = '';
	err_podoblasti = false; 
	err_podoblasti_poruka = '';
	err_preporuka = false; 
	err_preporuka_poruka = '';

	saveDisabled = false;
	loading = false;
	msg : any;
	stepen : any = null;

	fakulteti : any = [];
	fakulteti_svi : any = [];
	ustanove : any = [];
	poste : any = [];	
	godine : any = [{id:'1', naziv : '1'}, {id:'2', naziv : '2'}, {id:'3', naziv : '3'}, {id:'4', naziv : '4'}, {id:'5', naziv : '5'}, {id:'6', naziv : '6'}];

	razredi : any = [{id:'7', naziv : 'Sedmi'}, {id:'8', naziv : 'Osmi'}, {id:'9', naziv : 'Deveti'}, 
		{id:'101', naziv : 'Prvi'}, {id:'102', naziv : 'Drugi'}, {id:'103', naziv : 'Treći'}, {id:'104', naziv : 'Četvrti'}];

	nivoiStudija : any = [{id:null, naziv : ''}, {id:'O', naziv : 'Osnovne'}, {id:'M', naziv : 'Master'}];
	fakultet_podaci_ws = false; 
	kategorije : any = [];
	tipovi : any = [];
	takmicenja : any = [];
	podoblasti : any = [];

	ngOnInit() {
		window.scroll(0, 0);
		this.novi = this.id?false:true; 
		this.ucitaj();

		let elems = document.querySelectorAll('.tooltipped');
		let instances = M.Tooltip.init(elems, {});
	}

	ucitaj() {
		this.loading = true;
		this.http.get('prijave/traziprijavustip', { params: { id: this.id} })
			.subscribe(data => {
				this.loading = false;
				this.prijavastip = data;	
				
				//console.log('this.prijavastip', this.prijavastip);
				this.fakulteti = this.prijavastip.fakulteti;
				this.fakulteti_svi = [...this.fakulteti];
				this.poste = this.prijavastip.poste;
				this.kategorije = this.prijavastip.kategorije;
				this.tipovi = this.prijavastip.tipovi;
				this.takmicenja = this.prijavastip.takmicenja;

				//this.ucitajSkole();
				this.prijavastip.fakulteti = [];
				this.prijavastip.poste = [];
				this.prijavastip.kategorije = [];
				this.prijavastip.tipovi = [];
				this.prijavastip.takmicenja = [];

				if (this.novi){
					this.prijavastip.drzavljaninCG = '1';
					this.prijavastip.drzava = 'Crna Gora';
					this.prijavastip.studiraCG = '1';
					this.prijavastip.drzavaStud = 'Crna Gora';
				}
				setTimeout(() => {
					M.updateTextFields();
				}, 300);

				if(!this.novi)
					this.preporuka = '1';
			}
			, error => {
				this.loading = false;
			});
	}

	postaviDrzavljaninCG(value){
		this.prijavastip.drzavljaninCG = value;
		if (value === '1'){
			this.prijavastip.drzava = 'Crna Gora';
			//this.jmbgChanged();
		}
		else {
			this.prijavastip.drzava = '';
		}

		setTimeout(() => {
			M.updateTextFields();
		}, 100);
	}

	tipChanged(event){
		this.prijavastip.ime = '';
		this.prijavastip.prezime = '';
		this.prijavastip.imeRoditelja = '';
		this.prijavastip.datumRodjenja = '';					
		this.prijavastip.mjestoPrebivalista = '';
		this.prijavastip.ulicaBroj = '';
		this.prijavastip.pol = '';
		this.prijavastip.opstina = '';

		setTimeout(() => {
			M.updateTextFields();
		}, 100);
	}

	dataDisabled(){
		return !this.novi || this.prijavastip.idTipa !='3' || (this.prijavastip.idTipa=='3' && this.prijavastip.drzavljaninCG=='1');
	}
	postaviStudiraCG(value){
		this.prijavastip.studiraCG = value;
		if (value === '1'){
			this.prijavastip.drzavaStud = 'Crna Gora';
		}
		else {
			this.prijavastip.drzavaStud = '';
		}
		setTimeout(() => {
			M.updateTextFields();
		}, 100);
	}

	getNivoStudija(){
		if (this.prijavastip.nivoStudija == 'O')
			return 'Osnovne';
		else if (this.prijavastip.nivoStudija == 'M')
			return 'Master';
	}

	getUstanova(){
		if (this.prijavastip.srednjeSkole && this.prijavastip.srednjeSkole.length > 0)
			return this.prijavastip.srednjeSkole[0].naziv;
	}

	getFakultet(){
		if (this.prijavastip.fakulteti && this.prijavastip.fakulteti.length > 0){
			//console.log('fakulteti', this.prijavadom.fakulteti[0].naziv);
			return this.prijavastip.fakulteti[0].naziv;
		}
	}

	jmbgfocuslost(event){
		//console.log('focus lost', this.prijavaskola.jmbg);
	}

	toastTimeout = 3000;

	savedata() {
		try {
			if (!this.prijavastip.idTipa) {
				this.err_kategorija = true;
				this.err_kategorija_poruka = 'Morate definisati polje Tip prijave';
				M.toast({html: this.err_kategorija_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_kategorija = false;
			this.err_kategorija_poruka = '';

			/*if (!this.prijavastip.kreditPretGodine) {
				this.err_kreditPretGodine = true;
				this.err_kreditPretGodine_poruka = 'Morate definisati polje Student je već primao kredit ranijih godina?';
				M.toast({html: this.err_kreditPretGodine_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_kreditPretGodine = false;
			this.err_kreditPretGodine_poruka = '';*/

			if (this.prijavastip.idTipa == '3') {
				if (!this.prijavastip.drzavljaninCG) {
					this.err_drzavljaninCG = true;
					this.err_drzavljaninCG_poruka = 'Morate definisati polje Student je državljanin Crne Gore?';
					M.toast({html: this.err_drzavljaninCG_poruka, displayLength : this.toastTimeout});
					return;
				}
				this.err_drzavljaninCG = false;
				this.err_drzavljaninCG_poruka = '';

				if (!this.prijavastip.drzava) {
					this.err_drzava = true;
					this.err_drzava_poruka = 'Morate definisati polje Naziv države studenta?';
					M.toast({html: this.err_drzava_poruka, displayLength : this.toastTimeout});
					return;
				}
				this.err_drzava = false;
				this.err_drzava_poruka = '';

				if (!this.prijavastip.studiraCG) {
					this.err_studiraCG = true;
					this.err_studiraCG_poruka = 'Morate definisati polje Student studira u Crnoj Gori?';
					M.toast({html: this.err_studiraCG_poruka, displayLength : this.toastTimeout});
					return;
				}
				this.err_studiraCG = false;
				this.err_studiraCG_poruka = '';

				if (!this.prijavastip.drzavaStud) {
					this.err_drzavaStud = true;
					this.err_drzavaStud_poruka = 'Morate definisati polje Naziv države studiranja.';
					M.toast({html: this.err_drzavaStud_poruka, displayLength : this.toastTimeout});
					return;
				}
				this.err_drzavaStud = false;
				this.err_drzavaStud_poruka = '';

				if (!this.preporuka || this.preporuka === '0') {
					this.err_preporuka = true;
					this.err_preporuka_poruka = 'Samo studenti koji imaju preporuku profesora mogu konkurisati za stipendiju';
					M.toast({html: this.err_preporuka_poruka, displayLength : this.toastTimeout});
					return;
				}
				this.err_preporuka = false;
				this.err_preporuka_poruka = '';
			}
			
			if (!this.prijavastip.jmbg) {
				this.err_jmbg = true;
				this.err_jmbg_poruka = 'Morate definisati polje JMB (identifikacioni broj).';
				M.toast({html: this.err_jmbg_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_jmbg = false;
			this.err_jmbg_poruka = '';

			if (!this.prijavastip.ime) {
				this.err_ime = true;
				this.err_ime_poruka = 'Morate definisati polje Ime.';
				M.toast({html: this.err_ime_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_ime = false;
			this.err_ime_poruka = '';

			if (!this.prijavastip.prezime) {
				this.err_prezime = true;
				this.err_prezime_poruka = 'Morate definisati polje Prezime.';
				M.toast({html: this.err_prezime_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_prezime = false;
			this.err_prezime_poruka = '';
			
			if (!this.prijavastip.datumRodjenja) {
				this.err_datumRodjenja = true;
				this.err_datumRodjenja_poruka = 'Morate definisati polje Datum rođenja.';
				M.toast({html: this.err_datumRodjenja_poruka, displayLength : this.toastTimeout});
				return;
			}

			if (!this.globalSrvc.CheckDate(this.prijavastip.datumRodjenja)) {
				this.err_datumRodjenja = true;
				this.err_datumRodjenja_poruka = 'Nevalidan format za polje Datum rođenja.';
				M.toast({html: this.err_datumRodjenja_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_datumRodjenja = false;
			this.err_datumRodjenja_poruka = '';
			
			if (!this.prijavastip.pol) {
				this.err_pol = true;
				this.err_pol_poruka = 'Morate definisati polje Pol.';
				M.toast({html: this.err_pol_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_pol = false;
			this.err_pol_poruka = '';

			if (!this.prijavastip.opstina) {
				this.err_opstina = true;
				this.err_opstina_poruka = 'Morate definisati polje Opština.';
				M.toast({html: this.err_opstina_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_opstina = false;
			this.err_opstina_poruka = '';

			/*
			if (!this.prijavastip.mjestoPrebivalista) {
				this.err_mjestoPrebivalista = true;
				this.err_mjestoPrebivalista_poruka = 'Morate definisati polje Mjesto prebivališta.';
				M.toast({html: this.err_mjestoPrebivalista_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_mjestoPrebivalista = false;
			this.err_mjestoPrebivalista_poruka = '';
			*/
			/*if (!this.prijavastip.brojPoste) {
				this.err_brojPoste = true;
				this.err_brojPoste_poruka = 'Morate definisati polje Broj pošte.';
				M.toast({html: this.err_brojPoste_poruka, displayLength : this.toastTimeout});
				return;
			}

			if (!this.globalSrvc.CheckPostalNum(this.prijavastip.brojPoste)) {
				this.err_brojPoste = true;
				this.err_brojPoste_poruka = 'Polje Broj pošte mora imati tačno 5 cifara.';
				M.toast({html: this.err_brojPoste_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_brojPoste = false;
			this.err_brojPoste_poruka = '';*/

			if (!this.prijavastip.telefon){
				this.err_telefon = true;
				this.err_telefon_poruka = 'Morate definisati polje Broj telefona';
				M.toast({html: this.err_telefon_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_telefon = false;
			this.err_telefon_poruka = '';

			/*
			if (!this.prijavastip.brojLK){
				this.err_brojLK = true;
				this.err_brojLK_poruka = 'Morate definisati polje Reg. broj lične karte';
				M.toast({html: this.err_brojLK_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_brojLK = false;
			this.err_brojLK_poruka = '';

			if (!this.prijavastip.mjestoLK){
				this.err_mjestoLK = true;
				this.err_mjestoLK_poruka = 'Morate definisati polje Mjesto izdavanja lične karte';
				M.toast({html: this.err_mjestoLK_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_mjestoLK = false;
			this.err_mjestoLK_poruka = '';
			*/
			if (this.prijavastip.idTipa == '3') {
				if (this.prijavastip.studiraCG == '1'){
					if (!this.prijavastip.idFakulteta){
						this.err_idFakulteta = true;
						this.err_idFakulteta_poruka = 'Morate definisati polje Ustanova - fakultet';
						M.toast({html: this.err_idFakulteta_poruka, displayLength : this.toastTimeout});
						return;
					}
					this.err_idFakulteta = false;
					this.err_idFakulteta_poruka = '';
				}
				else {
					if (!this.prijavastip.nazivFakulteta){
						this.err_nazivFakulteta = true;
						this.err_nazivFakulteta_poruka = 'Morate definisati polje Fakultet';
						M.toast({html: this.err_nazivFakulteta_poruka, displayLength : this.toastTimeout});
						return;
					}
					this.err_nazivFakulteta = false;
					this.err_nazivFakulteta_poruka = '';
				}

				if (!this.prijavastip.nazivStudProg){
					this.err_nazivStudProg = true;
					this.err_nazivStudProg_poruka = 'Morate definisati polje Naziv studijskog programa';
					M.toast({html: this.err_nazivStudProg_poruka, displayLength : this.toastTimeout});
					return;
				}
				this.err_nazivStudProg = false;
				this.err_nazivStudProg_poruka = '';

				if (!this.prijavastip.nivoStudija){
					this.err_nivoStudija = true;
					this.err_nivoStudija_poruka = 'Morate definisati polje Vrsta studija';
					M.toast({html: this.err_nivoStudija_poruka, displayLength : this.toastTimeout});
					return;
				}
				this.err_nivoStudija = false;
				this.err_nivoStudija_poruka = '';

				if (!this.prijavastip.godina){
					this.err_godina = true;
					this.err_godina_poruka = 'Morate definisati polje Godina';
					M.toast({html: this.err_godina_poruka, displayLength : this.toastTimeout});
					return;
				}

				if (this.prijavastip.godina == '1' && this.prijavastip.nivoStudija == 'O'){
					this.err_godina = true;
					this.err_godina_poruka = 'Studenti I godine osnovih studija nemaju pravo konkursa za studentsku stipendiju';
					M.toast({html: this.err_godina_poruka, displayLength : this.toastTimeout});
					return;
				}
				this.err_godina = false;
				this.err_godina_poruka = '';

				this.err_godina = false;
				this.err_godina_poruka = '';

				if (!this.fakultet_podaci_ws && !this.prijavastip.indexUspjeha){
					this.err_indexUspjeha = true;
					this.err_indexUspjeha_poruka = 'Morate definisati polje Indeks uspjeha*';
					M.toast({html: this.err_indexUspjeha_poruka, displayLength : this.toastTimeout});
					return;
				}

				if (this.prijavastip.indexUspjeha && !this.globalSrvc.CheckNumber(this.prijavastip.indexUspjeha)){
					this.err_indexUspjeha = true;
					this.err_indexUspjeha_poruka = 'Nevalidan format za polje Indeks uspjeha ostvaren u prethodnoj studijskoj godini. Zadajte broj na najviše dvije decimale. ';
					M.toast({html: this.err_indexUspjeha_poruka, displayLength : this.toastTimeout});
					return;
				}

				this.err_indexUspjeha = false;
				this.err_indexUspjeha_poruka = '';

				if (!this.prijavastip.redniBrojUpisa){
					this.err_redniBrojUpisa = true;
					this.err_redniBrojUpisa_poruka = 'Redni broj upisa*';
					M.toast({html: this.err_redniBrojUpisa_poruka, displayLength : this.toastTimeout});
					return;
				}

				if (!this.globalSrvc.CheckInt(this.prijavastip.redniBrojUpisa)){
					this.err_redniBrojUpisa = true;
					this.err_redniBrojUpisa_poruka = 'Nevalidan format za polje Redni broj upisa.';
					M.toast({html: this.err_redniBrojUpisa_poruka, displayLength : this.toastTimeout});
					return;
				}

				this.err_indexUspjeha = false;
				this.err_indexUspjeha_poruka = '';
			}
			
			let url = 'prijave/stip/dodajprijavu';
			if (!this.novi)
				url = 'prijave/stip/izmijeniprijavu';
				
			
			this.loading = true;			
			setTimeout(() => {		
				this.http.post(url, this.prijavastip)
					.subscribe(data => {
						this.msg = data;
						this.loading = false;

						if (this.msg.id == 1) {
							if (this.prijavastip.id === undefined || this.prijavastip.id == null || this.prijavastip.id == '') {
								this.prijavastip.id = this.msg.newId;
							}
							//this.activeModal.close(this.prijavaskola);
							//this.globalSrvc.showSuccessModal();
							//M.toast({html : 'Uspješno', displayLength : this.toastTimeout});

							if (this.onInsertStip)
								this.onInsertStip.emit(this.prijavastip);
						}
						else {
							//this.globalSrvc.showErrorModal(this.msg.text);
							M.toast({html:this.msg.text, displayLength : this.toastTimeout});
						}
					}
					, error => {
						this.loading = false;
						//this.globalSrvc.showGenericErrorModal();
					}
				);
			}, 1000);			
		}
		catch (err) {

		}
	}
	crps_data: any = null;

	centralniregistar() {
		if (!this.prijavastip.idTipa) {
			this.err_kategorija = true;
			this.err_kategorija_poruka = 'Morate definisati polje Kategorija prijave';
			M.toast({html: this.err_kategorija_poruka, displayLength : this.toastTimeout});
			return;
		}
		this.err_kategorija = false;
		this.err_kategorija_poruka = '';
		this.loading = true;

		setTimeout(() => {			
		
			this.http.get(this.globalSrvc.rootUrl + 'prijave/stip/registri/pretraga/jmbg/', { params: { jmbg: this.prijavastip.jmbg, idTipaPrijave : this.prijavastip.idTipa, studiraCG : this.prijavastip.studiraCG} })
				.subscribe(data => {
					this.loading = false;			
					this.crps_data = data;
					
					if (this.crps_data && this.crps_data.errMsg){
						this.err_jmbg = true;
						this.err_jmbg_poruka = this.crps_data.errMsg + '!';
						M.toast({html: this.crps_data.errMsg + '!', displayLength : 4000});
						return;
					}
					//console.log(this.crps_data);
					if (this.crps_data.ime) {					
						this.prijavastip.ime = this.crps_data.ime;
						this.prijavastip.prezime = this.crps_data.prezime;
						this.prijavastip.imeRoditelja = this.crps_data.imeRoditelja;
						this.prijavastip.opstina = this.crps_data.opstina;
						this.prijavastip.mjestoPrebivalista = this.crps_data.mjestoPrebivalista;
						this.prijavastip.ulicaBroj = this.crps_data.ulicaBroj;

						this.prijavastip.drzavljanstvo = this.crps_data.drzavljanstvo;

						if (this.crps_data.datumRodjenja)
							this.prijavastip.datumRodjenja = this.crps_data.datumRodjenja;
						
						this.prijavastip.pol = this.crps_data.pol;
						this.prijavastip.drzavljaninCG = '1';
						this.prijavastip.drzava = 'Crna Gora';

						//podaci o fakultetu 
						this.prijavastip.idFakulteta = this.crps_data.idFakulteta; 
						this.prijavastip.godina = this.crps_data.godina; 
						this.prijavastip.indexUspjeha = this.crps_data.indexUspjeha; 
						this.prijavastip.brojIndeksa = this.crps_data.brojIndeksa; 
						this.prijavastip.nivoStudija = this.crps_data.nivoStudija; 
						this.prijavastip.redniBrojUpisa = this.crps_data.redniBrojUpisa;

						if (this.crps_data.idFakulteta)
							this.fakultet_podaci_ws = true;
							else {
								//ima ga u registru drzavljana ali nema podataka o faksu. makni ucg
								//console.log(this.fakulteti);
								try {
									this.fakulteti = this.fakulteti_svi.filter((item) => !item.naziv.startsWith('UNIVERZITET CRNE GORE'));
								}
								catch(e){}
							}

						setTimeout(() => {
							M.updateTextFields();
						}, 100);

						this.err_jmbg = false;
						this.err_jmbg_poruka = '';
					}
					else {
						this.fakultet_podaci_ws = false;
						this.err_jmbg = true;
						this.err_jmbg_poruka = 'U registrima nije nađen zadati JMB!';
						M.toast({html:  'U registrima nije nađen zadati JMB!', displayLength : 4000});

						this.prijavastip.ime = '';
						this.prijavastip.prezime = '';
						this.prijavastip.imeRoditelja = '';
						this.prijavastip.datumRodjenja = '';					
						this.prijavastip.mjestoPrebivalista = '';
						this.prijavastip.ulicaBroj =  '';
						this.prijavastip.pol = '';
						this.prijavastip.drzavljanstvo = '';
						this.prijavastip.opstina = '';
						return;
					}
				}
				, error => {
					this.loading = false;
				}
			);
		}, 500);
		/**/ 
	}

	cancel(){
		if (this.onCancel)
			this.onCancel.emit(null);
	}

	jmbgChanged(){		
		this.prijavastip.ime = '';
		this.prijavastip.prezime = '';
		this.prijavastip.imeRoditelja = '';
		this.prijavastip.datumRodjenja = '';					
		this.prijavastip.mjestoPrebivalista = '';
		this.prijavastip.ulicaBroj = '';
		this.prijavastip.pol = '';
		this.prijavastip.opstina = '';
		this.prijavastip.idFakulteta = ''; 
		this.prijavastip.godina = ''; 
		this.prijavastip.indexUspjeha = ''; 
		this.prijavastip.brojIndeksa = ''; 
		this.prijavastip.nivoStudija = ''; 

		setTimeout(() => {
			M.updateTextFields();
		}, 100);
	}

	getLuca(prijava){
		if (!prijava.luca || prijava.luca == '0') return ''; 
		
		if (prijava.luca == '1') return 'A';
		if (prijava.luca == '2') return 'B';
		return prijava.luca;
	}

}
