import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as M from "materialize-css/dist/js/materialize";
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

	constructor(private router :  Router, private http : HttpClient, public globalSrvc : GlobalService) { }

	user: any = { ime: '', prezime: '', drzava: '', opstina: '', pol: '', mail: '', br_tel: '', lozinka: '', lozinka_potvrdi: '' };
	saving : boolean = false;
	err_ime = false;
	err_ime_msg = '';
	err_prezime = false;
	err_prezime_msg = '';
	err_mail = false;
	err_mail_msg = '';
	err_pass = false;
	err_pass_msg = '';
	err_pass_c = false;
	err_pass_c_msg = '';
	badLogin = false;
	badLoginMsg : any = '';
	reg_msg : any = null;

	ngOnInit() {
	}

	login(){
		this.router.navigate(["loginPage"]);
	}

	register(){	
		//console.log(this.user);
		if (!this.user.ime){
			this.err_ime = true;
			this.err_ime_msg = 'Ime ne smije biti prazno';
			M.toast({html: 'Ime ne smije biti prazno!', displayLength : 1000});
			return;
		}
		this.err_ime = false;
		this.err_ime_msg = '';

		if (!this.user.prezime){
			this.err_prezime = true;
			this.err_prezime_msg = 'Prezime ne smije biti prazno';
			M.toast({html: 'Prezime ne smije biti prazno!', displayLength : 1000});
			return;
		}
		this.err_prezime = false;
		this.err_prezime_msg = '';

		if (!this.user.mail){
			this.err_mail = true;
			this.err_mail_msg = 'Mail adresa ne smije biti prazna';
			M.toast({html: 'Mail adresa ne smije biti prazna', displayLength : 1000});
			return;
		}

		if (!this.checkEmail(this.user.mail)){
			this.err_mail = true;
			this.err_mail_msg = 'Nevalidan format za mail adresu';
			M.toast({html: 'Nevalidan format za mail adresu', displayLength : 1000});
			return;
		}

		this.err_mail = false;
		this.err_mail_msg = '';

		if (!this.user.lozinka || this.user.lozinka.length < 6){
			this.err_pass = true;
			this.err_pass_msg = 'Lozinka mora imati makar 6 znaka';
			M.toast({html: 'Lozinka mora imati makar 6 znaka', displayLength : 1000});
			return;
		}

		this.err_pass = false;
		this.err_pass_msg = '';

		if (this.user.lozinka !== this.user.lozinka_potvrdi){
			this.err_pass_c = true;
			this.err_pass_c_msg = 'Lozinke se ne poklapaju';
			M.toast({html: 'Lozinke se ne poklapaju', displayLength : 1000});
			return;
		}

		this.err_pass_c = false;
		this.err_pass_c_msg = '';

		this.saving = true;
		setTimeout(() => {
			this.http.post(this.globalSrvc.rootUrl +'register', this.user)
			.subscribe(data => {
				console.log(data);
				this.reg_msg = data;
				this.saving = false;

				if(this.reg_msg.id == 1){
					this.router.navigate(["register-success"]);
				}
				else{
					this.badLogin = true;
					this.badLoginMsg = this.reg_msg.text;
				}
			}
			,error => {
				console.log(error);
				this.saving = false;				
			});
		}, 500);
	}

	checkEmail = (input) => {
		let regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    	return regexp.test(input.toLowerCase());
	}
}
