import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import * as M from "materialize-css/dist/js/materialize";
import * as $ from 'jquery';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
	selector: 'unos-izmjena-prijava-skola',
	templateUrl: './unos-izmena-prijava-skola.component.html',
	styleUrls: ['./unos-izmena-prijava-skola.component.css']
})
export class UnosIzmenaPrijavaSkolaComponent implements OnInit {

	constructor(private http: HttpClient, public globalSrvc: GlobalService) { }

	@Input() id;
	@Input() novi;
	@Input() jedinica;
	@Input() prekoportala;

	@Output() onInsertSkola = new EventEmitter<any>();
	@Output() onCancel = new EventEmitter<any>();
	@Output() onNoTermin = new EventEmitter<any>();

	prijavaskola: any = { 'id': '', 'idUstanove': '', 'brojJedinice': '', 'jmbg': '', 'ime': '', 'prezime': '', 'adresa': '', 'telefon': '', 'datumRodjenja': '', 'mjestoRodjenja': '', 'pol': '', 'imeOca': '', 'strucnaSpremaOca': '', 'jeOtacZaposlen': '', 'imeMajke': '', 'strucnaSpremaMajke': '', 'jeMajkaZaposlena': '', 'roditeljZiviId': '', 'brClabPorodice': '', 'brojDjeceUPorodici': '', 'brojPoReduRodjenja': '', 'vecPohadjalo': '', 'vecPohadjaloVrijeme': '', 'vecPohadjaloUklapanje': '', 'hraniSamostalno': '', 'oblaciSamostalno': '', 'pertleSamostalno': '', 'makazeSamostalno': '', 'toaletSamostalno': '', 'sredjuStvariSamostalno': '', 'kupujeSamostalno': '', 'odvajanjeOdBliskeOsobe': '', 'odvajanjeOdBliskeOsobeDrugo': '', 'odnosiSaDjecom': '', 'odnosiSaDjecomDrugo': '', 'reakcijaUskracenje': '', 'reakcijaUskracenjeDrugo': '', 'ranijeCitaPise': '', 'sportAktivnost': '', 'sportAktivnostOpis': '', 'dodatneAktivnosti': '', 'dodatneAktivnostiDrugo': '', 'vrijednaOsobina': '', 'dodatnaNapomena': '', 'potvrdaSistematski': '', 'porodicaMop': '', 'odlozenUpis': null, 'telefonMajke': '', 'imeStaratelja': '', idJezika : '', jezici : []};
	err_jmbg = false;
	err_jmbg_poruka = '';
	err_ime = false;
	err_ime_poruka = '';
	err_prezime = false;
	err_prezime_poruka = '';
	//datumRodjenja: NgbDateStruct;
	err_datumRodjenja = false;
	err_datumRodjenja_poruka = '';
	err_pol = false;
	err_pol_poruka = '';
	err_brClabPorodice = false;
	err_brClabPorodice_poruka = '';
	err_brojDjeceUPorodici = false;
	err_brojDjeceUPorodici_poruka = '';
	err_brojPoReduRodjenja = false;
	err_brojPoReduRodjenja_poruka = '';
	err_ustanova_poruka = '';
	err_ustanova = false; 
	err_jedinica_poruka = '';
	err_jedinica = false;
	err_telefon_otac = false;
	err_telefon_otac_poruka = '';
	err_adresa = false;
	err_adresa_poruka = '';
	err_ime_staratelja = false;
	err_ime_staratelja_poruka = '';
	err_vecPohadjalo = false;
	err_vecPohadjalo_poruka = '';
	err_jezik = false;
	err_jezik_poruka = '';

	saveDisabled = false;
	loading = false;
	msg: any;
	odlozenUpisStavke: any = [{ 'id': '', 'naziv': '', jeOdabran: false }, { 'id': '1', 'naziv': 'Prijedlog roditelja', jeOdabran: false }, { 'id': '2', 'naziv': 'Mišljenje nadležne zdravstvene ustanove', jeOdabran: false }, { 'id': '3', 'naziv': 'Komisija za upis djece u školu', jeOdabran: false }];
	ziviSaStavke : any = [{ 'id': '', 'naziv': '', jeOdabran: false }, { 'id': '1', 'naziv': 'Sa oba roditelja', jeOdabran: false }, { 'id': '2', 'naziv': 'Sa jednim roditeljem', jeOdabran: false }, { 'id': '3', 'naziv': 'Sa starateljem/hraniteljem', jeOdabran: false }, { 'id': '4', 'naziv': 'Dijete živi u ustanovi za nezbrinutu djecu', jeOdabran: false }];
	uklapanjeStavke : any = [{ 'id': '', 'naziv': '', jeOdabran: false }, { 'id': '0', 'naziv': 'Bez problema', jeOdabran: false }, { 'id': '1', 'naziv': 'Imalo je teškoća: u odvajanju, uključivanju u igru i sl.', jeOdabran: false }];
	odvajanjeStavke : any = [{ 'id': '', 'naziv': '', jeOdabran: false }, { 'id': '0', 'naziv': 'Bez problema', jeOdabran: false }, { 'id': '1', 'naziv': 'Sa strahom', jeOdabran: false }, { 'id': '2', 'naziv': 'Sa ljutnjom', jeOdabran: false }, { 'id': '3', 'naziv': 'Negoduje, ali pristaje', jeOdabran: false }, { 'id': '4', 'naziv': 'Drugo', jeOdabran: false }];
	odnosiDrDjecaStavke : any = [{ 'id': '', 'naziv': '', jeOdabran: false }, { 'id': '0', 'naziv': 'Lako se uključuje i prilagođava se bez problema', jeOdabran: false }, { 'id': '1', 'naziv': 'Povlači se', jeOdabran: false }, { 'id': '2', 'naziv': 'Nastoji da bude "glavno"', jeOdabran: false }, { 'id': '3', 'naziv': 'Bira manji broj djece sa kojom se igra/druži i sl.', jeOdabran: false }, { 'id': '4', 'naziv': 'Drugo', jeOdabran: false }];
	uskracenjeStavke : any = [{ 'id': '', 'naziv': '', jeOdabran: false }, { 'id': '0', 'naziv': 'Prihvata bez pogovora', jeOdabran: false }, { 'id': '1', 'naziv': 'Posluša, ali uz negodovanje', jeOdabran: false }, { 'id': '2', 'naziv': "Ostaje uporno", jeOdabran: false }, { 'id': '3', 'naziv': 'Burno reaguje (ljuti se, plače, odbija da posluša)', jeOdabran: false }, { 'id': '4', 'naziv': 'Lako odustaje', jeOdabran: false }, { 'id': '5', 'naziv': 'Pokušava da nađe zamjenu', jeOdabran: false }, { 'id': '6', 'naziv': 'Drugo', jeOdabran: false }];	
	dodatnoAngazovanoStavke : any = [{ 'id': '', 'naziv': '', jeOdabran: false }, { 'id': '1', 'naziv': 'muzika', jeOdabran: false }, { 'id': '2', 'naziv': 'crtanje', jeOdabran: false }, { 'id': '3', 'naziv': "ples", jeOdabran: false }, { 'id': '4', 'naziv': 'gluma', jeOdabran: false }, { 'id': '5', 'naziv': 'sport', jeOdabran: false }, { 'id': '6', 'naziv': 'drugo', jeOdabran: false }];	
	nivoKvalifikacije: any = [{ 'id': '', 'naziv': '', jeOdabran: false },
	{ 'id': 'Nepotpuna osnovna škola', 'naziv': 'Nepotpuna osnovna škola', jeOdabran: false },
	{ 'id': 'Osnovna škola', 'naziv': 'Osnovna škola', jeOdabran: false },
	{ 'id': 'Srednja škola', 'naziv': 'Srednja škola', jeOdabran: false },
	{ 'id': 'Viša škola', 'naziv': 'Viša škola', jeOdabran: false },
	{ 'id': 'Visoka škola', 'naziv': 'Visoka škola', jeOdabran: false },
	{ 'id': 'Magistar nauka', 'naziv': 'Magistar nauka', jeOdabran: false },
	{ 'id': 'Doktor nauka', 'naziv': 'Doktor nauka', jeOdabran: false }
	];

	kolikoVremenaStavke : any = [{ 'id': 'manje od jedne godine', 'naziv': 'manje od jedne godine', jeOdabran: false }, 
								{ 'id': '1 godinu', 'naziv': '1 godinu', jeOdabran: false },
								{ 'id': '2 godine', 'naziv': '2 godine', jeOdabran: false },
								{ 'id': '3 godine', 'naziv': '3 godine', jeOdabran: false },
								{ 'id': '4 godine', 'naziv': '4 godine', jeOdabran: false },
								{ 'id': '5 godina', 'naziv': '5 godina', jeOdabran: false },
							];

	brClanPorodiceStavke: any = [{ 'id': '', 'naziv': '', jeOdabran: false }];
	brDjeceStavke: any = [{ 'id': '', 'naziv': '', jeOdabran: false }];
	dijetePoRodjenuStavke : any = [{ 'id': '', 'naziv': '', jeOdabran: false }];

	jmbgvalid = false;
	skole : any = [];
	jedinice : any = [];
	jeziciStavke : any = [];

	ngOnInit() {
		if (this.id)
			this.ucitaj();
		else 
			this.ucitajSkole();

		let elems = document.querySelectorAll('.tooltipped');
		let instances = M.Tooltip.init(elems, {});
		//console.log('elems', elems);
		elems = document.querySelectorAll('.datepicker');
        instances = M.Datepicker.init(elems, { 
			firstDay: true, 
			format: 'dd.mm.yyyy',
			i18n: {
				months: ["Januar", "Februar", "Mart", "April", "Maj", "Jun", "Jul", "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"],
				monthsShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],
				weekdays: ["Nedjelja","Ponedjeljak", "Utorak", "Srijeda", "Četvrtak", "Petak", "Subota"],
				weekdaysShort: ["Ned","Pon", "Uto", "Sri", "Čet", "Pet", "Sub"],
				weekdaysAbbrev: ["N","P", "U", "S", "Č", "P", "S"]
			}
		});
	}

	initSifrarnici(){
		for(let i = 1 ; i <= 22; i++)
			this.brClanPorodiceStavke.push({ 'id': i + '', 'naziv': i + '', jeOdabran: false });

		for(let i = 1 ; i <= 20; i++)
			this.brDjeceStavke.push({ 'id': i + '', 'naziv': i + '', jeOdabran: false });
		
		for(let i = 1 ; i <= 20; i++)
			this.dijetePoRodjenuStavke.push({ 'id': i + '', 'naziv': i + '', jeOdabran: false });
	}

	jmbgfocuslost(event){
		//console.log('focus lost', this.prijavaskola.jmbg);
	}

	ucitajSkole() {
		this.initSifrarnici();

		this.loading = true;
		this.http.get('prijave/skole', { params: { id: this.id } })
			.subscribe(data => {
				this.loading = false;
				this.skole = data;
				this.skole = this.skole.ustanove;
				//console.log(this.skole);
				if (this.id)
					this.skolaselected(null);
				
			}
			, error => {
				this.loading = false;
			});
	}

	ucitajJezike() {
		this.loading = true;
		this.jeziciStavke = [];

		this.http.get('prijave/skola/jezici', { params: { idUstanove: this.prijavaskola.idUstanove} })
		.subscribe(data => {
			this.loading = false;
			this.jeziciStavke = data;

			if (this.jeziciStavke && this.jeziciStavke.length == 1)
				this.prijavaskola.idJezika = this.jeziciStavke[0].id;
			else 
				this.prijavaskola.idJezika = null;
		}
		, error => {
			this.loading = false;
		});
	}

	ucitaj() {
		this.loading = true;
		this.http.get('prijave/prijavaskola', { params: { id: this.id } })
			.subscribe(data => {
				this.loading = false;
				this.prijavaskola = data;	
				this.jeziciStavke = this.prijavaskola.jezici;
				//console.log('this.prijavaskola', this.prijavaskola);			
				setTimeout(() => {
					M.updateTextFields();
				}, 100);
				this.ucitajSkole();
			}
			, error => {
				this.loading = false;
			});
	}

	changedate(val){
		//console.log(val);
	}

	skolaselected(event){
		//console.log(this.prijavaskola.idUstanove, event);
		let skola = this.skole.find(item => item.id == this.prijavaskola.idUstanove);
		
		this.loading = true;

		setTimeout(() => {
			this.http.get(this.globalSrvc.rootUrl + 'prijave/postojislobodni', { params: { idUstanove: this.prijavaskola.idUstanove} })
				.subscribe(data => {
					this.loading = false;			
					this.msg = data;
					//console.log('data', data);
					this.jedinice = skola.jedinice;

					if (this.msg.id == 1){
						if (!this.id){
							this.prijavaskola.brojJedinice = null;

							if (this.jedinice && this.jedinice.length == 1)
								this.prijavaskola.brojJedinice = this.jedinice[0].brojJedinice;
							else 
								this.prijavaskola.brojJedinice = null;
						}
						if (!this.id)
							this.ucitajJezike();
					}
					else {
						if (this.onNoTermin && !this.id)
							this.onNoTermin.emit();
					}
				}
				, error => {
					this.loading = false;
				}
			);
		}, 300);
	}

	printobj(){
		//console.log(this.prijavaskola);
	}
	toastTimeout = 3000;

	savedata() {
		try {
			if (this.prijavaskola.idUstanove === undefined || this.prijavaskola.idUstanove == null || this.prijavaskola.idUstanove == '') {
				this.err_ustanova = true;
				this.err_ustanova_poruka = 'Morate odabrati ustanovu';
				M.toast({html: this.err_ustanova_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_ustanova = false;
			this.err_ustanova_poruka = '';

			if (this.prijavaskola.brojJedinice === undefined || this.prijavaskola.brojJedinice == null || this.prijavaskola.brojJedinice == '') {
				this.err_jedinica = true;
				this.err_jedinica_poruka = 'Morate odabrati jedinicu';
				M.toast({html: this.err_jedinica_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_jedinica = false;
			this.err_jedinica_poruka = '';

			if (this.prijavaskola.idJezika === undefined || this.prijavaskola.idJezika == null || this.prijavaskola.idJezika == '') {
				this.err_jezik = true;
				this.err_jezik_poruka = 'Morate definisati polje Nastavni jezik';
				M.toast({html: this.err_jezik_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_jezik = false;
			this.err_jezik_poruka = '';
			
			if (this.prijavaskola.jmbg === undefined || this.prijavaskola.jmbg == null || this.prijavaskola.jmbg == '') {
				this.err_jmbg = true;
				this.err_jmbg_poruka = 'Morate definisati polje JMB.';
				M.toast({html: this.err_jmbg_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_jmbg = false;
			this.err_jmbg_poruka = '';

			if (this.prijavaskola.ime === undefined || this.prijavaskola.ime == null || this.prijavaskola.ime == '') {
				this.err_ime = true;
				this.err_ime_poruka = 'Morate definisati polje Ime. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_ime_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_ime = false;
			this.err_ime_poruka = '';

			if (this.prijavaskola.prezime === undefined || this.prijavaskola.prezime == null || this.prijavaskola.prezime == '') {
				this.err_prezime = true;
				this.err_prezime_poruka = 'Morate definisati polje Prezime. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_prezime_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_prezime = false;
			this.err_prezime_poruka = '';

			if (this.prijavaskola.datumRodjenja === undefined || this.prijavaskola.datumRodjenja == null) {
				this.err_datumRodjenja = true;
				this.err_datumRodjenja_poruka = 'Morate definisati polje Datum rođenja. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_datumRodjenja_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_datumRodjenja = false;
			this.err_datumRodjenja_poruka = '';
			
			if (this.prijavaskola.adresa === undefined || this.prijavaskola.adresa == null || this.prijavaskola.adresa == '') {
				this.err_adresa = true;
				this.err_adresa_poruka = 'Morate definisati polje Adresa. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_adresa_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_adresa = false;
			this.err_adresa_poruka = '';

			if (this.prijavaskola.telefon === undefined || this.prijavaskola.telefon == null || this.prijavaskola.telefon == '') {
				this.err_telefon_otac = true;
				this.err_telefon_otac_poruka = 'Morate definisati polje Telefon roditelja/staratelja/hranitelja';
				M.toast({html: this.err_telefon_otac_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_telefon_otac = false;
			this.err_telefon_otac_poruka = '';

			if (this.prijavaskola.pol === undefined || this.prijavaskola.pol == null || this.prijavaskola.pol == '') {
				this.err_pol = true;
				this.err_pol_poruka = 'Morate definisati polje Pol. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_pol_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_pol = false;
			this.err_pol_poruka = '';			

			if (this.prijavaskola.roditeljZiviId == '3'){
				if (this.prijavaskola.imeStaratelja === undefined || this.prijavaskola.imeStaratelja == null || this.prijavaskola.imeStaratelja == '') {
					this.err_ime_staratelja = true;
					this.err_ime_staratelja_poruka = 'Ime i prezime staratelja/hranitelja';
					M.toast({html: this.err_ime_staratelja_poruka, displayLength : this.toastTimeout});
					return;
				}
				this.err_ime_staratelja = false;
				this.err_ime_staratelja_poruka = '';
			}

			if (!(this.prijavaskola.brClabPorodice === undefined || this.prijavaskola.brClabPorodice == null || this.prijavaskola.brClabPorodice == '')) {
				if (!this.globalSrvc.CheckNumber(this.prijavaskola.brClabPorodice)) {
					this.err_brClabPorodice = true;
					this.err_brClabPorodice_poruka = 'Nevalidan format za polje Broj članova porodice';
					M.toast({html: this.err_brClabPorodice_poruka, displayLength : this.toastTimeout});
					return;
				}
			}
			this.err_brClabPorodice = false;
			this.err_brClabPorodice_poruka = '';

			if (!(this.prijavaskola.brojDjeceUPorodici === undefined || this.prijavaskola.brojDjeceUPorodici == null || this.prijavaskola.brojDjeceUPorodici == '')) {
				if (!this.globalSrvc.CheckNumber(this.prijavaskola.brojDjeceUPorodici)) {
					this.err_brojDjeceUPorodici = true;
					this.err_brojDjeceUPorodici_poruka = 'Nevalidan format za polje Broj djece u porodici';
					M.toast({html: this.err_brojDjeceUPorodici_poruka, displayLength : this.toastTimeout});
					return;
				}
			}
			this.err_brojDjeceUPorodici = false;
			this.err_brojDjeceUPorodici_poruka = '';

			if (!(this.prijavaskola.brojPoReduRodjenja === undefined || this.prijavaskola.brojPoReduRodjenja == null || this.prijavaskola.brojPoReduRodjenja == '')) {
				if (!this.globalSrvc.CheckNumber(this.prijavaskola.brojPoReduRodjenja)) {
					this.err_brojPoReduRodjenja = true;
					this.err_brojPoReduRodjenja_poruka = 'Nevalidan format za polje Broj djece u porodici';
					M.toast({html: this.err_brojPoReduRodjenja_poruka, displayLength : this.toastTimeout});
					return;
				}
			}
			this.err_brojPoReduRodjenja = false;
			this.err_brojPoReduRodjenja_poruka = '';

			if (this.prijavaskola.vecPohadjalo === undefined || this.prijavaskola.vecPohadjalo == null || this.prijavaskola.vecPohadjalo == ''){
				this.err_vecPohadjalo = true;
				this.err_vecPohadjalo_poruka = 'Morate definisati polje Dijete je već pohađalo ustanovu. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_vecPohadjalo_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_vecPohadjalo = false;
			this.err_vecPohadjalo_poruka = '';

			let url = 'prijave/izmijeniprijavuskola';
			if (this.prijavaskola.id === undefined || this.prijavaskola.id == null || this.prijavaskola.id == '') {
				url = 'prijave/dodajprijavuskola';
			}
			this.loading = true;

			//this.prijavaskola.datumRodjenja = this.globalSrvc.NGBStructToDate(this.datumRodjenja);
			//this.prijavaskola.brojJedinice = this.jedinica.broj;

			setTimeout(() => {				
			
				this.http.post(url, this.prijavaskola)
					.subscribe(data => {
						this.msg = data;
						this.loading = false;

						if (this.msg.id == 1) {
							if (this.prijavaskola.id === undefined || this.prijavaskola.id == null || this.prijavaskola.id == '') {
								this.prijavaskola.id = this.msg.newId;
							}
							//this.activeModal.close(this.prijavaskola);
							//this.globalSrvc.showSuccessModal();
							//M.toast({html : 'Uspješno', displayLength : this.toastTimeout});

							if (this.onInsertSkola)
								this.onInsertSkola.emit(this.prijavaskola);
						}
						else {
							//this.globalSrvc.showErrorModal(this.msg.text);
							M.toast({html:this.msg.text, displayLength : this.toastTimeout});
						}
					}
					, error => {
						this.loading = false;
						//this.globalSrvc.showGenericErrorModal();
					}
				);
			}, 1000);			
		}
		catch (err) {

		}
	}
	crps_data: any = null;

	centralniregistar() {
		if (this.prijavaskola.idUstanove === undefined || this.prijavaskola.idUstanove == null || this.prijavaskola.idUstanove == '') {
			this.err_ustanova = true;
			this.err_ustanova_poruka = 'Morate odabrati ustanovu';
			M.toast({ html: this.err_ustanova_poruka, displayLength: this.toastTimeout });
			return;
		}
		this.err_ustanova = false;
		this.err_ustanova_poruka = '';

		if (this.prijavaskola.brojJedinice === undefined || this.prijavaskola.brojJedinice == null || this.prijavaskola.brojJedinice == '') {
			this.err_jedinica = true;
			this.err_jedinica_poruka = 'Morate odabrati jedinicu';
			M.toast({ html: this.err_jedinica_poruka, displayLength: this.toastTimeout });
			return;
		}
		this.err_jedinica = false;
		this.err_jedinica_poruka = '';
		/*/
		this.prijavaskola.ime = 'Marko';
		this.prijavaskola.prezime = 'Markovic';
		this.prijavaskola.imeOca = 'Milo';
		this.prijavaskola.imeMajke = 'Milodarka';
		this.prijavaskola.adresa = 'Krusi b.b.';
		this.prijavaskola.datumRodjenja = '02.02.2015';
		
		this.prijavaskola.mjestoRodjenja = 'Podgorica';
		this.prijavaskola.opstinaRodjenja = 'Podgorica';
		this.prijavaskola.drzavaRodjenja = 'Crna Gora';
		this.prijavaskola.mjestoPrebivalista = 'Podgorica';
		this.prijavaskola.pol = 'M';
		this.prijavaskola.drzavljanstvo = '1';
		this.prijavaskola.vecPohadjalo = '1';
		this.prijavaskola.porodicaMop = '0';
		//console.log('prijava skola', this.prijavaskola);
		if (this.prijavaskola.ime) $( "#lbl-ime" ).addClass( "active" );
		if (this.prijavaskola.prezime) $( "#lbl-prezime" ).addClass( "active" );
		if (this.prijavaskola.adresa) $( "#lbl-adresa" ).addClass( "active" );
		if (this.prijavaskola.datumRodjenja) $( "#lbl-d-rodjenja" ).addClass( "active" );
		if (this.prijavaskola.mjestoRodjenja) $( "#lbl-m-rodjenja" ).addClass( "active" );
		if (this.prijavaskola.imeOca) $( "#lbl-ime-oca" ).addClass( "active" );
		if (this.prijavaskola.imeMajke) $( "#lbl-ime-majke" ).addClass( "active" ); 
		/**/
		
		/**/
		this.loading = true;

		setTimeout(() => {
			this.http.get(this.globalSrvc.rootUrl + 'prijave/registri/pretraga/jmbg/', { params: { jmbg: this.prijavaskola.jmbg, tip:'skola'} })
				.subscribe(data => {
					this.loading = false;
					//console.log(data);					
					this.crps_data = data;

					if (this.crps_data && this.crps_data.errMsg){
						this.err_jmbg = true;
						this.err_jmbg_poruka = this.crps_data.errMsg + '!';
						M.toast({html: this.crps_data.errMsg + '!', displayLength : 4000});
						return;
					}
					
					if (this.crps_data.ime != null && this.crps_data.ime != '') {
						
						this.prijavaskola.ime = this.crps_data.ime;
						this.prijavaskola.prezime = this.crps_data.prezime;
						this.prijavaskola.imeOca = this.crps_data.imeOca;
						this.prijavaskola.imeMajke = this.crps_data.imeMajke;
						this.prijavaskola.adresa = this.crps_data.adresa;

						if (!(this.crps_data.datumRodjenja === undefined) && this.crps_data.datumRodjenja != null && this.crps_data.datumRodjenja != '')
							this.prijavaskola.datumRodjenja = this.crps_data.datumRodjenja;
						
						this.prijavaskola.mjestoRodjenja = this.crps_data.mjestoRodjenja;
						this.prijavaskola.opstinaRodjenja = this.crps_data.opstinaRodjenja;
						this.prijavaskola.drzavaRodjenja = this.crps_data.drzavaRodjenja;
						this.prijavaskola.mjestoPrebivalista = this.crps_data.mjestoPrebivalistaPunNaziv;
						this.prijavaskola.pol = this.crps_data.pol;
						this.prijavaskola.drzavljanstvo = this.crps_data.idDrzavljanstva;
						this.prijavaskola.vecPohadjalo = this.crps_data.vecPohadjalo;
						this.prijavaskola.porodicaMop = this.crps_data.korisnikMaterijalno;

						if (this.prijavaskola.ime) $( "#lbl-ime" ).addClass( "active" );
						if (this.prijavaskola.prezime) $( "#lbl-prezime" ).addClass( "active" );
						if (this.prijavaskola.adresa) $( "#lbl-adresa" ).addClass( "active" );
						if (this.prijavaskola.datumRodjenja) $( "#lbl-d-rodjenja" ).addClass( "active" );
						if (this.prijavaskola.mjestoRodjenja) $( "#lbl-m-rodjenja" ).addClass( "active" );
						if (this.prijavaskola.imeOca) $( "#lbl-ime-oca" ).addClass( "active" );
						if (this.prijavaskola.imeMajke) $( "#lbl-ime-majke" ).addClass( "active" );

						this.err_jmbg = false;
						this.err_jmbg_poruka = '';
					}
					else {
						this.err_jmbg = true;
						this.err_jmbg_poruka = 'U registrima nije nađen zadati JMB!';
						M.toast({html:  'U registrima nije nađen zadati JMB!', displayLength : 4000});

						this.prijavaskola.ime = '';
						this.prijavaskola.prezime = '';
						this.prijavaskola.imeOca = '';
						this.prijavaskola.imeMajke = '';
						this.prijavaskola.adresa = '';
						this.prijavaskola.datumRodjenja = '';					
						this.prijavaskola.mjestoRodjenja = '';
						this.prijavaskola.opstinaRodjenja = '';
						this.prijavaskola.drzavaRodjenja = '';
						this.prijavaskola.mjestoPrebivalista = '';
						this.prijavaskola.pol = '';
						this.prijavaskola.drzavljanstvo = '';
						this.prijavaskola.vecPohadjalo = '';
						this.prijavaskola.porodicaMop = '';
						return;
					}
				}
				, error => {
					this.loading = false;
				}
			);
		}, 500);
		/**/ 
	}

	cancel(){
		if (this.onCancel)
			this.onCancel.emit(null);
	}

	jmbgChanged(){
		this.prijavaskola.ime = '';
		this.prijavaskola.prezime = '';
		this.prijavaskola.imeOca = '';
		this.prijavaskola.imeMajke = '';
		this.prijavaskola.adresa = '';
		this.prijavaskola.datumRodjenja = '';					
		this.prijavaskola.mjestoRodjenja = '';
		this.prijavaskola.opstinaRodjenja = '';
		this.prijavaskola.drzavaRodjenja = '';
		this.prijavaskola.mjestoPrebivalista = '';
		this.prijavaskola.pol = '';
		this.prijavaskola.drzavljanstvo = '';
		this.prijavaskola.vecPohadjalo = '';
		this.prijavaskola.porodicaMop = '';

		setTimeout(() => {
			M.updateTextFields();
		}, 100);
	}

}

