import { Component, OnInit } from '@angular/core';
import * as M from "materialize-css/dist/js/materialize";

@Component({
	selector: 'rules',
	templateUrl: './rules.component.html',
	styleUrls: ['./rules.component.css']
})
export class RulesComponent implements OnInit {

	constructor() { }

	ngOnInit() {
		var elems = document.querySelectorAll('.collapsible');
		var instances = M.Collapsible.init(elems, {});
	}

}
