import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import * as M from "materialize-css/dist/js/materialize";

@Component({
	selector: 'unos-izmjena-fakultet',
	templateUrl: './unos-izmjena-fakultet.component.html',
	styleUrls: ['./unos-izmjena-fakultet.component.css']
})
export class UnosIzmjenaFakultetComponent implements OnInit {

	constructor(private http: HttpClient, public globalSrvc: GlobalService) { }

	@Input() id;
	@Input() novi;
	@Input() jedinica;
	@Input() prekoportala;

	@Output() onInsertFakultet = new EventEmitter<any>();
	@Output() onCancel = new EventEmitter<any>();

	prijavafakultet : any = {'id' : '', 'idFakulteta' : '', 'idStudPrograma' : '', 'jmbg' : '', 'ime' : '', 'prezime' : '', 'opstina' : '', 'adresa' : '', 'telefon' : '', 'email' : '', 'datumRodjenja' : '', 'mjestoRodjenja' : '', 'pol' : '', 'imeOca' : '', 'imeMajke' : '', 'drzavljanstvo' : '', 'prekoPortala' : '', 'userId' : '', 'fakulteti' : [], 'studProgrami' : []};
	err_jmbg = false;
	err_jmbg_poruka = '';
	err_ime = false;
	err_ime_poruka = '';
	err_prezime = false;
	err_prezime_poruka = '';
	err_smjerId = false;
	err_smjerId_poruka = '';
	err_opstina = false;
	err_opstina_poruka = '';
	err_adresa = false;
	err_adresa_poruka = '';
	err_datumRodjenja = false;
	err_datumRodjenja_poruka = '';
	err_mjestoRodjenja = false;
	err_mjestoRodjenja_poruka = '';
	err_pol = false;
	err_pol_poruka = '';
	err_imeOca = false;
	err_imeOca_poruka = '';
	err_imeMajke = false;
	err_imeMajke_poruka = '';
	err_fakultet_poruka = '';
	err_fakultet = false; 
	err_studprog_poruka = '';
	err_studprog = false; 

	err_jedinica_poruka = '';
	err_jedinica = false;
	err_telefonOca = false;
	err_telefonOca_poruka = '';
	err_emailOca = false;
	err_emailOca_poruka = '';
	err_ime_staratelja = false;
	err_ime_staratelja_poruka = '';
	err_prvi_strani = false;
	err_prvi_strani_poruka = '';
	err_drugi_strani = false;
	err_drugi_strani_poruka = '';
	err_jezik = false;
	err_jezik_poruka = '';	
	err_telefon = false;
	err_telefon_poruka = '';

	saveDisabled = false;
	loading = false;
	msg : any;
	stepen : any = null;

	fakulteti : any = [];
	studProgrami : any = [];

	ngOnInit() {
		window.scroll(0, 0);
		this.ucitaj();
		let elems = document.querySelectorAll('.tooltipped');
		let instances = M.Tooltip.init(elems, {});
	}

	ucitaj() {
		this.loading = true;
		this.http.get('prijave/traziprijavufakultet', { params: { id: this.id } })
			.subscribe(data => {
				this.loading = false;
				this.prijavafakultet = data;	
				
				//console.log('this.prijavafakultet', this.prijavafakultet);
				this.fakulteti = this.prijavafakultet.fakulteti;

				if (this.id){
					this.ucitajStudPrograme();
				}

				setTimeout(() => {
					M.updateTextFields();
				}, 300);
				
				//this.ucitajSkole();
			}
			, error => {
				this.loading = false;
			});
	}

	jmbgfocuslost(event){
		//console.log('focus lost', this.prijavaskola.jmbg);
	}

	fakultetselected(event){
		this.ucitajStudPrograme();
	}

	ucitajStudPrograme(){
		this.loading = true;
		this.studProgrami = [];		

		this.http.get('prijave/fakulteti/studprogrami', { params: { idFakulteta: this.prijavafakultet.idFakulteta } })
			.subscribe(data => {
				this.loading = false;
				this.studProgrami = data;

				if (!this.id){					
					this.prijavafakultet.idStudPrograma = null;
				}
			}
			,error => {
				this.loading = false;
			}
		);
	}

	toastTimeout = 3000;

	savedata() {
		try {
			if (!this.prijavafakultet.idFakulteta) {
				this.err_fakultet = true;
				this.err_fakultet_poruka = 'Morate odabrati Fakultet';
				M.toast({html: this.err_fakultet_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_fakultet = false;
			this.err_fakultet_poruka = '';

			if (!this.prijavafakultet.idStudPrograma) {
				this.err_studprog = true;
				this.err_studprog_poruka = 'Morate definisati polje Studijski program';
				M.toast({html: this.err_studprog_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_studprog = false;
			this.err_studprog_poruka = '';

			if (this.prijavafakultet.jmbg === undefined || this.prijavafakultet.jmbg == null || this.prijavafakultet.jmbg == '') {
				this.err_jmbg = true;
				this.err_jmbg_poruka = 'Morate definisati polje JMB.';
				M.toast({html: this.err_jmbg_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_jmbg = false;
			this.err_jmbg_poruka = '';

			if (this.prijavafakultet.ime === undefined || this.prijavafakultet.ime == null || this.prijavafakultet.ime == '') {
				this.err_ime = true;
				this.err_ime_poruka = 'Morate definisati polje Ime. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_ime_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_ime = false;
			this.err_ime_poruka = '';

			if (this.prijavafakultet.prezime === undefined || this.prijavafakultet.prezime == null || this.prijavafakultet.prezime == '') {
				this.err_prezime = true;
				this.err_prezime_poruka = 'Morate definisati polje Prezime. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_prezime_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_prezime = false;
			this.err_prezime_poruka = '';

			/*if (this.prijavaskola.opstina === undefined || this.prijavaskola.opstina == null || this.prijavaskola.opstina == ''){
				this.err_opstina = true;
				this.err_opstina_poruka = 'Morate definisati polje Opština. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_opstina_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_opstina = false;
			this.err_opstina_poruka = '';

			if (this.prijavaskola.adresa === undefined || this.prijavaskola.adresa == null || this.prijavaskola.adresa == '') {
				this.err_adresa = true;
				this.err_adresa_poruka = 'Morate definisati polje Adresa. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_adresa_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_adresa = false;
			this.err_adresa_poruka = '';
			*/
			if (this.prijavafakultet.datumRodjenja === undefined || this.prijavafakultet.datumRodjenja == null) {
				this.err_datumRodjenja = true;
				this.err_datumRodjenja_poruka = 'Morate definisati polje Datum rođenja. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_datumRodjenja_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_datumRodjenja = false;
			this.err_datumRodjenja_poruka = '';

			if (this.prijavafakultet.mjestoRodjenja === undefined || this.prijavafakultet.mjestoRodjenja == null || this.prijavafakultet.mjestoRodjenja == ''){
				this.err_mjestoRodjenja = true;
				this.err_mjestoRodjenja_poruka = 'Morate definisati polje Mjesto rođenja. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_mjestoRodjenja_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_mjestoRodjenja = false;
			this.err_mjestoRodjenja_poruka = '';

			if (this.prijavafakultet.pol === undefined || this.prijavafakultet.pol == null || this.prijavafakultet.pol == '') {
				this.err_pol = true;
				this.err_pol_poruka = 'Morate definisati polje Pol. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_pol_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_pol = false;
			this.err_pol_poruka = '';

			/*if (this.prijavaskola.imeOca === undefined || this.prijavaskola.imeOca == null || this.prijavaskola.imeOca == ''){
				this.err_imeOca = true;
				this.err_imeOca_poruka = 'Morate definisati polje Ime oca';
				M.toast({html: this.err_imeOca_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_imeOca = false;
			this.err_imeOca_poruka = '';
	
			if (this.prijavaskola.imeMajke === undefined || this.prijavaskola.imeMajke == null || this.prijavaskola.imeMajke == ''){
				this.err_imeMajke = true;
				this.err_imeMajke_poruka = 'Morate definisati polje Ime majke';
				M.toast({html: this.err_imeMajke, displayLength : this.toastTimeout});
				return;
			}
			this.err_imeMajke = false;
			this.err_imeMajke_poruka = '';*/

			if (this.prijavafakultet.telefon === undefined || this.prijavafakultet.telefon == null || this.prijavafakultet.telefon == ''){
				this.err_telefon = true;
				this.err_telefon_poruka = 'Morate definisati polje Broj telefona';
				M.toast({html: this.err_telefon_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_telefon = false;
			this.err_telefon_poruka = '';

			let url = 'prijave/dodajprijavufakultet';
			if (this.prijavafakultet.id === undefined || this.prijavafakultet.id == null || this.prijavafakultet.id == ''){
				url = 'prijave/dodajprijavufakultet';
			}
			this.loading = true;
			//console.log(this.prijavaskola);
			setTimeout(() => {				
			
				this.http.post(url, this.prijavafakultet)
					.subscribe(data => {
						this.msg = data;
						this.loading = false;

						if (this.msg.id == 1) {
							if (this.prijavafakultet.id === undefined || this.prijavafakultet.id == null || this.prijavafakultet.id == '') {
								this.prijavafakultet.id = this.msg.newId;
							}
							//this.activeModal.close(this.prijavaskola);
							//this.globalSrvc.showSuccessModal();
							//M.toast({html : 'Uspješno', displayLength : this.toastTimeout});

							if (this.onInsertFakultet)
								this.onInsertFakultet.emit(this.prijavafakultet);
						}
						else {
							//this.globalSrvc.showErrorModal(this.msg.text);
							M.toast({html:this.msg.text, displayLength : this.toastTimeout});

							if (this.msg && this.msg.text && this.msg.text.startsWith('Smjer koji ste')){								
								this.err_studprog = true;
								this.err_studprog_poruka = this.msg.text;
							}
						}
					}
					, error => {
						this.loading = false;
						//this.globalSrvc.showGenericErrorModal();
					}
				);
			}, 1000);			
		}
		catch (err) {

		}
	}
	crps_data: any = null;

	centralniregistar() {

		/*/
		if (this.crps_data && this.crps_data.errMsg){
			this.err_jmbg = true;
			this.err_jmbg_poruka = this.crps_data.errMsg + '!';
			M.toast({html: this.crps_data.errMsg + '!', displayLength : 4000});
			//return;
		}

		this.prijavafakultet.ime = 'Marko';
		this.prijavafakultet.prezime = 'Markovic';
		this.prijavafakultet.imeOca = 'Milo';
		this.prijavafakultet.imeMajke = 'Milodarka';
		this.prijavafakultet.opstina = 'Podgorica';
		this.prijavafakultet.adresa = 'Krusi b.b.';
		this.prijavafakultet.datumRodjenja = '02.02.2015';
		
		this.prijavafakultet.mjestoRodjenja = 'Podgorica';
		this.prijavafakultet.opstinaRodjenja = 'Podgorica';
		this.prijavafakultet.drzavaRodjenja = 'Crna Gora';
		this.prijavafakultet.mjestoPrebivalista = 'Podgorica';
		this.prijavafakultet.pol = 'M';
		//console.log('prijava skola', this.prijavaskola);
		setTimeout(() => {
			M.updateTextFields();
		}, 100);
		/**/

		/**/ 

		this.loading = true;

		setTimeout(() => {			
		
			this.http.get(this.globalSrvc.rootUrl + 'prijave/fakulteti/registri/pretraga/jmbg/', { params: { jmbg: this.prijavafakultet.jmbg, tip:'skola'} })
				.subscribe(data => {
					this.loading = false;			
					this.crps_data = data;
					
					if (this.crps_data && this.crps_data.errMsg){
						this.err_jmbg = true;
						this.err_jmbg_poruka = this.crps_data.errMsg + '!';
						M.toast({html: this.crps_data.errMsg + '!', displayLength : 4000});
						return;
					}
					
					if (this.crps_data.ime != null && this.crps_data.ime != '') {						
						this.prijavafakultet.ime = this.crps_data.ime;
						this.prijavafakultet.prezime = this.crps_data.prezime;
						this.prijavafakultet.imeOca = this.crps_data.imeOca;
						this.prijavafakultet.imeMajke = this.crps_data.imeMajke;
						this.prijavafakultet.adresa = this.crps_data.adresa;
						this.prijavafakultet.opstina = this.crps_data.opstina;
						this.prijavafakultet.drzavljanstvo = this.crps_data.drzavljanstvo;

						if (!(this.crps_data.datumRodjenja === undefined) && this.crps_data.datumRodjenja != null && this.crps_data.datumRodjenja != '')
							this.prijavafakultet.datumRodjenja = this.crps_data.datumRodjenja;
						
						this.prijavafakultet.mjestoRodjenja = this.crps_data.mjestoRodjenja;
						this.prijavafakultet.opstinaRodjenja = this.crps_data.opstinaRodjenja;
						this.prijavafakultet.drzavaRodjenja = this.crps_data.drzavaRodjenja;
						this.prijavafakultet.mjestoPrebivalista = this.crps_data.mjestoPrebivalistaPunNaziv;
						this.prijavafakultet.pol = this.crps_data.pol;
						
						setTimeout(() => {
							M.updateTextFields();
						}, 100);

						this.err_jmbg = false;
						this.err_jmbg_poruka = '';
					}
					else {
						this.err_jmbg = true;
						this.err_jmbg_poruka = 'U registrima nije nađen zadati JMB!';
						M.toast({html:  'U registrima nije nađen zadati JMB!', displayLength : 4000});

						this.prijavafakultet.ime = '';
						this.prijavafakultet.prezime = '';
						this.prijavafakultet.imeOca = '';
						this.prijavafakultet.imeMajke = '';
						this.prijavafakultet.adresa = '';
						this.prijavafakultet.datumRodjenja = '';					
						this.prijavafakultet.mjestoRodjenja = '';
						this.prijavafakultet.opstinaRodjenja = '';
						this.prijavafakultet.drzavaRodjenja = '';
						this.prijavafakultet.mjestoPrebivalista = '';
						this.prijavafakultet.pol = '';
						this.prijavafakultet.drzavljanstvo = '';
						return;
					}
				}
				, error => {
					this.loading = false;
				}
			);
		}, 500);
		/**/ 
	}

	cancel(){
		if (this.onCancel)
			this.onCancel.emit(null);
	}

	jmbgChanged(){
		this.prijavafakultet.ime = '';
		this.prijavafakultet.prezime = '';
		this.prijavafakultet.imeOca = '';
		this.prijavafakultet.imeMajke = '';
		this.prijavafakultet.adresa = '';
		this.prijavafakultet.datumRodjenja = '';					
		this.prijavafakultet.mjestoRodjenja = '';
		this.prijavafakultet.opstinaRodjenja = '';
		this.prijavafakultet.drzavaRodjenja = '';
		this.prijavafakultet.mjestoPrebivalista = '';
		this.prijavafakultet.pol = '';
		this.prijavafakultet.opstina = '';
		this.prijavafakultet.drzavljanstvo = '';

		setTimeout(() => {
			M.updateTextFields();
		}, 100);
	}

}
