import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as M from "materialize-css/dist/js/materialize";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
	selector: 'zakazivanje-muzicka',
	templateUrl: './zakazivanje-muzicka.component.html',
	styleUrls: ['./zakazivanje-muzicka.component.css']
})
export class ZakazivanjeMuzickaComponent implements OnInit {

	constructor(private http : HttpClient) { }

	@Input() prijava;
	err_datum = false;
	err_datum_poruka = '';
	termindatum : any = null;
	datumi : any = [];
	loading = false;
	termini : any = [];
	odabraniTermin : any = null;
	odabraniDatum : any = null;
	err_msg = '';

	@Output() onZakazi = new EventEmitter<any>();
	@Output() onCancel = new EventEmitter<any>();

	ngOnInit() {
		//console.log('prijava', this.prijava);
		this.ucitajDatume();
	}

	ucitajDatume() {
		this.loading = true;
		this.termini = [];
		this.odabraniTermin = null; 
		this.odabraniDatum = null;

		setTimeout(() => {
			this.http.get('prijave/slobodnidatumimuzicka', { params: { idUstanove: this.prijava.idUstanove, idPrijave : this.prijava.id } })
				.subscribe(data => {
					this.loading = false;
					this.datumi = data;
					if (!this.datumi || this.datumi.length == 0){
						var elem = document.getElementById('modal3');				
						var instance = M.Modal.init(elem, {onCloseEnd : () => {this.cancel();}});
						instance.open();
					}
					//console.log(this.datumi);
				}
				, error => {
					this.loading = false;
				}
			);
		}, 1000);
	}

	datumselected(event){
		//console.log('datum', this.termindatum);
		this.ucitajTermine(this.termindatum);
	}

	ucitajTermine(datum) {
		this.loading = true;
		this.termini = [];
		this.odabraniTermin = null;

		setTimeout(() => {
			this.http.get('prijave/slobodnimuz', { params: { idUstanove: this.prijava.idUstanove, datum :  datum, idPrijave : this.prijava.id} })
				.subscribe(data => {
					this.loading = false;
					this.termini = data;
				}
				, error => {
					this.loading = false;
				}
			);
		}, 1000);
	}

	odaberiTermin(termin){
		this.odabraniTermin = termin;
	}

	format(num){
		if (num < 10)
			return '0' + num;
		else 
			return num;
	}
	msg : any = null;
	toastTimeout = 4000;

	savedata() {
		try {
			if (!this.termindatum) {
				M.toast({html: 'Morate odabrati datum',  displayLength : this.toastTimeout});
				return;
			}
			if (!this.odabraniTermin) {
				M.toast({html: 'Morate odabrati termin',  displayLength : this.toastTimeout});
				return;
			}
			let url = 'prijave/termin/zakazimuz';
			this.loading = true;
			
			let obj = {termin_id : this.odabraniTermin.id, prijava_id : this.prijava.id, datum : this.termindatum, vrijemePocetka : (this.odabraniTermin.satPocetka + ":" + this.odabraniTermin.minutPocetka)};
			

			setTimeout(() => {				
			
				this.http.post(url, obj)
					.subscribe(data => {
						this.msg = data;
						this.loading = false;

						if (this.msg.id == 1) {
							//M.toast({html : 'Uspješno zakazivanje', displayLength : this.toastTimeout});

							if (this.onZakazi)
								this.onZakazi.emit(this.prijava);
						}
						else {
							//this.globalSrvc.showErrorModal(this.msg.text);
							//M.toast({html:this.msg.text, displayLength : this.toastTimeout});
							this.err_msg = this.msg.text;
							var elem = document.getElementById('modal4');				
							var instance = M.Modal.init(elem, {onCloseEnd : () => {this.ucitajDatume();}});
							instance.open();
						}
					}
					, error => {
						this.loading = false;
						//this.globalSrvc.showGenericErrorModal();
					}
				);
			}, 1000);			
		}
		catch (err) {
			this.loading = false;
		}
	}

	cancel(){
		if (this.onCancel)
			this.onCancel.emit(null);
	}
}
