import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import * as M from "materialize-css/dist/js/materialize";
import * as $ from 'jquery';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
	selector: 'unos-izmjena-prijava-muzicka',
	templateUrl: './unos-izmjena-prijava-muzicka.component.html',
	styleUrls: ['./unos-izmjena-prijava-muzicka.component.css']
})
export class UnosIzmjenaPrijavaMuzickaComponent implements OnInit {

	constructor(private http: HttpClient, public globalSrvc: GlobalService) { }

	@Input() id;
	@Input() novi;
	@Input() jedinica;
	@Input() prekoportala;

	@Output() onInsertMuzSkola = new EventEmitter<any>();
	@Output() onCancel = new EventEmitter<any>();
	@Output() onNoTermin = new EventEmitter<any>();

	prijavaskola : any = {'id' : '', 'idUstanove' : '', 'brojJedinice' : '', 'idRazreda' : '','nazivRazreda' : '', 'idSmjera' : '','nazivSmjera' : '', 'idStatusa' : '','nazivStatusa' : '', 'jmbg' : '', 'ime' : '', 'prezime' : '', 'adresa' : '', 'datumRodjenja' : '', 'mjestoRodjenja' : '', 'pol' : '', 'imeOca' : '', 'imeMajke' : '', 'telefonOca' : '', 'telefonMajka' : '', 'emailOca' : '', 'emailMajke' : '', 'zanimanjeOca' : '', 'zanimanjeMajke' : '', 'idUstanoveOsnovno' : '', 'prekoPortala' : '', 'razredi' : [], 'smjerovi' : [], 'statusi' : [], 'ustanoveIX' : [], 'podnesenaPrijava' : '', 'opcijaPripremni' : '', 'nazivUstanoveOsnovno' : '', 'napomena' : ''};
	
	err_ustanova_poruka = '';
	err_ustanova = false; 
	err_jedinica_poruka = '';
	err_jedinica = false;
	err_idRazreda = false;
	err_idRazreda_poruka = '';
	smjerovi : any = [];
	err_idSmjera = false;
	err_idSmjera_poruka = '';
	statusi : any = [];
	err_idStatusa = false;
	err_idStatusa_poruka = '';
	err_jmbg = false;
	err_jmbg_poruka = '';
	err_ime = false;
	err_ime_poruka = '';
	err_prezime = false;
	err_prezime_poruka = '';
	err_adresa = false;
	err_adresa_poruka = '';	
	err_datumRodjenja = false;
	err_datumRodjenja_poruka = '';
	err_pol = false;
	err_pol_poruka = '';
	err_imeOca = false; 
	err_imeOca_poruka = '';
	err_telefonOca = false;
	err_telefonOca_poruka = '';
	ustanoveIX : any = [];
	err_prekoPortala = false;
	err_prekoPortala_poruka = '';
	saveDisabled = false;
	loading = false;
	msg: any;

	jmbgvalid = false;
	skole : any = [];
	jedinice : any = [];

	opcijePripremniMuz : any = [{ 'id': '', 'naziv': '', jeOdabran: false }, { 'id': '1', 'naziv': 'Muzička', jeOdabran: false },{ 'id': '2', 'naziv': 'Baletska', jeOdabran: false }];
	opcijePripremniBezBal : any = [{ 'id': '', 'naziv': '', jeOdabran: false }, { 'id': '1', 'naziv': 'Muzička', jeOdabran: false }];
	opcijePripremni : any = [{ 'id': '', 'naziv': '', jeOdabran: false }, { 'id': '1', 'naziv': 'Muzička', jeOdabran: false }];
	razredi : any = [{ 'id': '', 'naziv': '', jeOdabran: false },{ 'id': '1', 'naziv': 'prvi', jeOdabran: false } , { 'id': '2', 'naziv': 'drugi', jeOdabran: false }, { 'id': '3', 'naziv': 'treći', jeOdabran: false },
	{ 'id': '4', 'naziv': 'četvrti', jeOdabran: false } , { 'id': '5', 'naziv': 'peti', jeOdabran: false }, { 'id': '6', 'naziv': 'šesti', jeOdabran: false },
	{ 'id': '7', 'naziv': 'sedmi', jeOdabran: false } , { 'id': '8', 'naziv': 'osmi', jeOdabran: false }, { 'id': '9', 'naziv': 'deveti', jeOdabran: false }
	];
	ngOnInit() {
		if (this.id)
			this.ucitaj();
		else 
			this.ucitajSkole();

		let elems = document.querySelectorAll('.tooltipped');
		let instances = M.Tooltip.init(elems, {});
		//console.log('elems', elems);
		elems = document.querySelectorAll('.datepicker');
        instances = M.Datepicker.init(elems, { 
			firstDay: true, 
			format: 'dd.mm.yyyy',
			i18n: {
				months: ["Januar", "Februar", "Mart", "April", "Maj", "Jun", "Jul", "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"],
				monthsShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],
				weekdays: ["Nedjelja","Ponedjeljak", "Utorak", "Srijeda", "Četvrtak", "Petak", "Subota"],
				weekdaysShort: ["Ned","Pon", "Uto", "Sri", "Čet", "Pet", "Sub"],
				weekdaysAbbrev: ["N","P", "U", "S", "Č", "P", "S"]
			}
		});
	}

	ucitaj() {
		this.loading = true;
		this.http.get('prijave/traziprijavumuzskola', { params: { id: this.id } })
			.subscribe(data => {
				this.loading = false;
				this.prijavaskola = data;	
				this.smjerovi = this.prijavaskola.smjerovi;	
				//console.log('this.prijavaskola', this.prijavaskola);			
				
				setTimeout(() => {
					M.updateTextFields();
				}, 100);
				this.ucitajSkole();
			}
			, error => {
				this.loading = false;
			});
	}

	ucitajSkole() {
		this.loading = true;
		this.http.get('prijave/muzskole', { params: { id: this.id } })
			.subscribe(data => {
				this.loading = false;
				this.skole = data;
				this.skole = this.skole.ustanove;
				//console.log(this.skole);
				if (this.id)
					this.skolaselected(null);
				
			}
			, error => {
				this.loading = false;
			});
	}

	skolaselected(event){
		//console.log(this.prijavaskola.idUstanove, event);
		if (this.prijavaskola.jmbg && this.novi){
			this.prijavaskola.jmbg = '';
			this.jmbgChanged();
		}
		let skola = this.skole.find(item => item.id == this.prijavaskola.idUstanove);		
		this.loading = true;

		setTimeout(() => {
			this.http.get(this.globalSrvc.rootUrl + 'prijave/postojislobodnimuzicka', { params: { idUstanove: this.prijavaskola.idUstanove} })
				.subscribe(data => {
					this.loading = false;			
					this.msg = data;
					//console.log('data', data);
					this.jedinice = skola.jedinice;

					if (this.msg.id == 1){
						if (!this.id){
							this.prijavaskola.brojJedinice = null;

							if (this.jedinice && this.jedinice.length == 1)
								this.prijavaskola.brojJedinice = this.jedinice[0].brojJedinice;
							else 
								this.prijavaskola.brojJedinice = null;
						}
						/*if (!this.id)
							this.ucitajsmjerove();*/
					}
					else {
						if (this.onNoTermin && !this.id)
							this.onNoTermin.emit();
					}
				}
				, error => {
					this.loading = false;
				}
			);
		}, 300);
	}

	ucitajsmjerove(){
		this.loading = true;
		this.smjerovi = [];		

		this.http.get('prijave/srednja/smjerovi', { params: { idUstanove: this.prijavaskola.idUstanove } })
			.subscribe(data => {
				this.loading = false;
				this.smjerovi = data;
				//console.log(this.smjerovi);

				if (!this.id){
					/*if (this.smjerovi && this.smjerovi.length == 1)
						this.prijavaskola.smjerId = this.smjerovi[0].id;
					else 
						this.prijavaskola.smjerId = null;*/
					this.prijavaskola.smjerId = null;
				}
			}
			,error => {
				this.loading = false;
			}
		);
	}

	jmbgfocuslost(event){
		//console.log('focus lost', this.prijavaskola.jmbg);
	}

	crps_data: any = null;
	toastTimeout = 3000;

	centralniregistar() {
		if (this.prijavaskola.idUstanove === undefined || this.prijavaskola.idUstanove == null || this.prijavaskola.idUstanove == '') {
			this.err_ustanova = true;
			this.err_ustanova_poruka = 'Morate odabrati ustanovu';
			M.toast({ html: this.err_ustanova_poruka, displayLength: this.toastTimeout });
			return;
		}
		this.err_ustanova = false;
		this.err_ustanova_poruka = '';
		/*
		if (this.prijavaskola.idUstanove == '1011' || this.prijavaskola.idUstanove == '5015')
			this.opcijePripremni = this.opcijePripremniMuz;
		else 
			this.opcijePripremni = this.opcijePripremniBezBal;
		*/
		
		/*/
		if (this.crps_data && this.crps_data.errMsg){
			this.err_jmbg = true;
			this.err_jmbg_poruka = this.crps_data.errMsg + '!';
			M.toast({html: this.crps_data.errMsg + '!', displayLength : 4000});
			//return;
		}

		this.prijavaskola.ime = 'Marko';
		this.prijavaskola.prezime = 'Markovic';
		this.prijavaskola.imeOca = 'Milo';
		this.prijavaskola.imeMajke = 'Milodarka';
		this.prijavaskola.opstina = 'Podgorica';
		this.prijavaskola.adresa = 'Krusi b.b.';
		this.prijavaskola.datumRodjenja = '02.02.2015';
		
		this.prijavaskola.mjestoRodjenja = 'Podgorica';
		this.prijavaskola.opstinaRodjenja = 'Podgorica';
		this.prijavaskola.drzavaRodjenja = 'Crna Gora';
		this.prijavaskola.mjestoPrebivalista = 'Podgorica';
		this.prijavaskola.pol = 'M';
		this.prijavaskola.drzavljanstvo = '1';
		this.prijavaskola.vecPohadjalo = '1';
		this.prijavaskola.porodicaMop = '0';
		//console.log('prijava skola', this.prijavaskola);
		setTimeout(() => {
			M.updateTextFields();
		}, 100);
		/**/

		/**/ 

		this.loading = true;

		setTimeout(() => {			
		
			this.http.get(this.globalSrvc.rootUrl + 'prijave/registri/muzicka/pretraga/jmbg/', { params: { jmbg: this.prijavaskola.jmbg, idUstanove : this.prijavaskola.idUstanove} })
				.subscribe(data => {
					this.loading = false;			
					this.crps_data = data;
					
					if (this.crps_data && this.crps_data.errMsg){
						this.err_jmbg = true;
						this.err_jmbg_poruka = this.crps_data.errMsg + '!';
						M.toast({html: this.crps_data.errMsg + '!', displayLength : 4000});
						return;
					}
					
					if (this.crps_data.ime != null && this.crps_data.ime != '') {						
						this.prijavaskola.ime = this.crps_data.ime;
						this.prijavaskola.prezime = this.crps_data.prezime;
						this.prijavaskola.imeOca = this.crps_data.imeOca;
						this.prijavaskola.imeMajke = this.crps_data.imeMajke;
						this.prijavaskola.adresa = this.crps_data.adresa;
						this.prijavaskola.opstina = this.crps_data.opstina;

						if (!(this.crps_data.datumRodjenja === undefined) && this.crps_data.datumRodjenja != null && this.crps_data.datumRodjenja != '')
							this.prijavaskola.datumRodjenja = this.crps_data.datumRodjenja;
						
						this.prijavaskola.mjestoRodjenja = this.crps_data.mjestoRodjenja;
						this.prijavaskola.opstinaRodjenja = this.crps_data.opstinaRodjenja;
						this.prijavaskola.drzavaRodjenja = this.crps_data.drzavaRodjenja;
						this.prijavaskola.mjestoPrebivalista = this.crps_data.mjestoPrebivalistaPunNaziv;
						this.prijavaskola.pol = this.crps_data.pol;
						/*this.prijavaskola.drzavljanstvo = this.crps_data.idDrzavljanstva;
						this.prijavaskola.etnika = this.crps_data.etnika;
						this.prijavaskola.imaRjesenjeKat = this.crps_data.imaRjesenjeKat;
						this.prijavaskola.idGrupeStrani = this.crps_data.idGrupeStrani; */
						this.prijavaskola.idUstanoveOsnovno = this.crps_data.idUstanoveOsnovno;
						this.prijavaskola.podnesenaPrijava = this.crps_data.podnesenaPrijava;
						this.prijavaskola.opcijaPripremni = '';						
						this.prijavaskola.idSmjera = '';
						this.prijavaskola.idRazreda = this.crps_data.idRazreda;
						this.prijavaskola.nazivUstanoveOsnovno = this.crps_data.nazivUstanoveOsnovno;
						this.smjerovi = this.crps_data.smjerovi;

						setTimeout(() => {
							M.updateTextFields();
						}, 100);

						this.err_jmbg = false;
						this.err_jmbg_poruka = '';
					}
					else {
						this.err_jmbg = true;
						this.err_jmbg_poruka = 'U registrima nije nađen zadati JMB!';
						M.toast({html:  'U registrima nije nađen zadati JMB!', displayLength : 4000});

						this.prijavaskola.ime = '';
						this.prijavaskola.prezime = '';
						this.prijavaskola.imeOca = '';
						this.prijavaskola.imeMajke = '';
						this.prijavaskola.adresa = '';
						this.prijavaskola.datumRodjenja = '';					
						this.prijavaskola.mjestoRodjenja = '';
						this.prijavaskola.opstinaRodjenja = '';
						this.prijavaskola.drzavaRodjenja = '';
						this.prijavaskola.mjestoPrebivalista = '';
						this.prijavaskola.pol = '';
						this.prijavaskola.drzavljanstvo = '';
						this.prijavaskola.podnesenaPrijava = '';
						this.prijavaskola.opcijaPripremni = '';
						this.prijavaskola.idUstanoveOsnovno = '';
						this.prijavaskola.idSmjera = '';
						this.prijavaskola.idRazreda = '';
						this.prijavaskola.nazivUstanoveOsnovno = '';
						return;
					}
				}
				, error => {
					this.loading = false;
				}
			);
		}, 500);
		/**/ 
	}

	cancel(){
		if (this.onCancel)
			this.onCancel.emit(null);
	}

	jmbgChanged(){
		this.prijavaskola.ime = '';
		this.prijavaskola.prezime = '';
		this.prijavaskola.imeOca = '';
		this.prijavaskola.imeMajke = '';
		this.prijavaskola.adresa = '';
		this.prijavaskola.datumRodjenja = '';					
		this.prijavaskola.mjestoRodjenja = '';
		this.prijavaskola.opstinaRodjenja = '';
		this.prijavaskola.drzavaRodjenja = '';
		this.prijavaskola.mjestoPrebivalista = '';
		this.prijavaskola.pol = '';
		this.prijavaskola.drzavljanstvo = '';
		this.prijavaskola.podnesenaPrijava = '';
		this.prijavaskola.opcijaPripremni = '';
		this.prijavaskola.idUstanoveOsnovno = '';
		this.prijavaskola.idSmjera = '';
		this.prijavaskola.idRazreda = '';
		this.prijavaskola.nazivUstanoveOsnovno = '';

		setTimeout(() => {
			M.updateTextFields();
		}, 100);
	}

	savedata() {
		try {
			if (this.prijavaskola.idUstanove === undefined || this.prijavaskola.idUstanove == null || this.prijavaskola.idUstanove == '') {
				this.err_ustanova = true;
				this.err_ustanova_poruka = 'Morate odabrati ustanovu';
				M.toast({html: this.err_ustanova_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_ustanova = false;
			this.err_ustanova_poruka = '';

			if (this.prijavaskola.brojJedinice === undefined || this.prijavaskola.brojJedinice == null || this.prijavaskola.brojJedinice == '') {
				this.err_jedinica = true;
				this.err_jedinica_poruka = 'Morate odabrati jedinicu';
				M.toast({html: this.err_jedinica_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_jedinica = false;
			this.err_jedinica_poruka = '';

			if (this.prijavaskola.jmbg === undefined || this.prijavaskola.jmbg == null || this.prijavaskola.jmbg == '') {
				this.err_jmbg = true;
				this.err_jmbg_poruka = 'Morate definisati polje JMB.';
				M.toast({html: this.err_jmbg_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_jmbg = false;
			this.err_jmbg_poruka = '';

			if (this.prijavaskola.podnesenaPrijava === '1'){
				if (!this.prijavaskola.opcijaPripremni) {
					this.err_idSmjera = true;
					this.err_idSmjera_poruka = 'Morate definisati polje Usmjerenje.';
					M.toast({html: this.err_idSmjera_poruka, displayLength : this.toastTimeout});
					return;
				}	
			}

			if (this.prijavaskola.podnesenaPrijava === '0'){
				if (!this.prijavaskola.idSmjera) {
					this.err_idSmjera = true;
					this.err_idSmjera_poruka = 'Morate definisati polje Usmjerenje.';
					M.toast({html: this.err_idSmjera_poruka, displayLength : this.toastTimeout});
					return;
				}				
			}

			this.err_idSmjera = false;
			this.err_idSmjera_poruka = '';

			if (this.prijavaskola.ime === undefined || this.prijavaskola.ime == null || this.prijavaskola.ime == '') {
				this.err_ime = true;
				this.err_ime_poruka = 'Morate definisati polje Ime. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_ime_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_ime = false;
			this.err_ime_poruka = '';

			if (this.prijavaskola.prezime === undefined || this.prijavaskola.prezime == null || this.prijavaskola.prezime == '') {
				this.err_prezime = true;
				this.err_prezime_poruka = 'Morate definisati polje Prezime. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_prezime_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_prezime = false;
			this.err_prezime_poruka = '';

			if (this.prijavaskola.datumRodjenja === undefined || this.prijavaskola.datumRodjenja == null) {
				this.err_datumRodjenja = true;
				this.err_datumRodjenja_poruka = 'Morate definisati polje Datum rođenja. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_datumRodjenja_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_datumRodjenja = false;
			this.err_datumRodjenja_poruka = '';
			
			/*if (this.prijavaskola.adresa === undefined || this.prijavaskola.adresa == null || this.prijavaskola.adresa == '') {
				this.err_adresa = true;
				this.err_adresa_poruka = 'Morate definisati polje Adresa. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_adresa_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_adresa = false;
			this.err_adresa_poruka = '';*/

			if (this.prijavaskola.pol === undefined || this.prijavaskola.pol == null || this.prijavaskola.pol == '') {
				this.err_pol = true;
				this.err_pol_poruka = 'Morate definisati polje Pol. Ovaj podatak se preuzima iz registara klikom na dugme pored polja JMB.';
				M.toast({html: this.err_pol_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_pol = false;
			this.err_pol_poruka = '';			

			/*if (!this.prijavaskola.imeOca) {
				this.err_imeOca = true;
				this.err_imeOca_poruka = 'Morate definisati polje Ime roditelja/staratelja/hranitelja';
				M.toast({html: this.err_imeOca_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_imeOca = false;
			this.err_imeOca_poruka = '';*/

			if (!this.prijavaskola.telefonOca) {
				this.err_telefonOca = true;
				this.err_telefonOca_poruka = 'Morate definisati polje Telefon roditelja/staratelja/hranitelja';
				M.toast({html: this.err_telefonOca_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_telefonOca = false;
			this.err_telefonOca_poruka = '';

			let url = 'prijave/izmijeniprijavumuzskola';
			if (this.prijavaskola.id === undefined || this.prijavaskola.id == null || this.prijavaskola.id == '') {
				url = 'prijave/dodajprijavumuzskola';
			}

			this.loading = true;

			//this.prijavaskola.datumRodjenja = this.globalSrvc.NGBStructToDate(this.datumRodjenja);
			//this.prijavaskola.brojJedinice = this.jedinica.broj;

			setTimeout(() => {				
			
				this.http.post(url, this.prijavaskola)
					.subscribe(data => {
						this.msg = data;
						this.loading = false;

						if (this.msg.id == 1) {
							if (this.prijavaskola.id === undefined || this.prijavaskola.id == null || this.prijavaskola.id == '') {
								this.prijavaskola.id = this.msg.newId;
							}
							//this.activeModal.close(this.prijavaskola);
							//this.globalSrvc.showSuccessModal();
							//M.toast({html : 'Uspješno', displayLength : this.toastTimeout});

							if (this.onInsertMuzSkola)
								this.onInsertMuzSkola.emit(this.prijavaskola);
						}
						else {
							//this.globalSrvc.showErrorModal(this.msg.text);
							M.toast({html:this.msg.text, displayLength : this.toastTimeout});
						}
					}
					, error => {
						this.loading = false;
						//this.globalSrvc.showGenericErrorModal();
					}
				);
			}, 1000);			
		}
		catch (err) {

		}
	}
}
