import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as M from "materialize-css/dist/js/materialize";

@Injectable()
export class GlobalService {

    constructor(private router: Router) { }
    
    rootUrl = '';
	public credentials = { username: '', password: '' };
	//credentialsChanged: Observable<any>
	authenticated = false;
	//authenticatedChanged: Observable<any>
	authenticatedObserver: any;
	credentialsObserver: any;
	user = '';
	idUstanove = '';
	nazivUstanove = '';
	domeni = [];
    odabraniDomen: any = {};
    isLogged = false;

    loginRoute = 'loginPage';
    
    public logout(){
        localStorage.setItem('acmetoken', '');
    }

	loadUser(data:any){
        //console.log(data);
        this.setAuthenticated(true);
        this.user=data.username;
        this.credentials.username =data.username;
	}
	
	setAuthenticated(authenticated:any) {
        this.authenticated = authenticated;
    }
    setCredentials(credentials:any) {
        this.credentials = credentials;
    }
    setUser(user:any){
        this.credentials.username = user;
        //this.credentialsObserver.next(this.credentials);
    }
    getCredentials(){
        return this.credentials;
    }
        
    cleardata(){
        this.setAuthenticated(false);
        this.credentials = {username : '', password : ''};
        //this.globalSrvc.odjeljenjeClass='active';
        this.user='';
	}   
	
	CheckInt(inputVal){
        let intPatern = /^\d+$/;
        if (!(intPatern.test(inputVal))){
            return false;
        }
        let intVal = parseInt(inputVal, 10);
        if (!Number.isInteger(intVal)){
            return false;
        }
        return true;
    }

    CheckJmbg(inputVal){
        let intPatern = /^\d+$/;
        if (!(intPatern.test(inputVal))){
            return false;
        }
        if (inputVal.length != 13){
            return false;
        }
        return true;
    }

    CheckNumber(inputVal){
        let floatPatern = /^\d+(\.\d+)?$/;
        if (!(floatPatern.test(inputVal))){
            return false;
        }
        let floatVal = parseFloat(inputVal);
        if (isNaN(floatVal)){
            return false;
        }
        return true;
    }

    CheckDate(inputVal){
        if (!inputVal) return null;

        let intPatern = /^\d{2,2}\.\d{2,2}\.\d{4,4}$/;
        if (!(intPatern.test(inputVal)))
            return false;
        
        if (inputVal.length != 10) return false;

        const dateParts = inputVal.trim().split('.');        
        if (dateParts.length < 3) return false;

        let d = parseInt(dateParts[0], 10);
        let m = parseInt(dateParts[1], 10) - 1;
        let y = parseInt(dateParts[2], 10);
        
        return m >= 0 && m < 12 && d > 0 && d <= this.daysInMonth(m, y);
    }

    CheckPostalNum(inputVal){
        let intPatern = /^\d+$/;
        if (!(intPatern.test(inputVal))){
            return false;
        }
        if (inputVal.length != 5){
            return false;
        }
        return true;
    }

    daysInMonth(m, y) {
        switch (m) {
            case 1 :
                return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
            case 8 : case 3 : case 5 : case 10 :
                return 30;
            default :
                return 31
        }
    }

    redirectToHome(){
        this.router.navigate(["homePage"]);
    }

    redirectToLogin(){
        this.cleardata();
        this.router.navigate(["loginPage"]);
        //this.router.navigate(["register"]);
    }

    initControls(){
        document.addEventListener('DOMContentLoaded', function () {
            var elems = document.querySelectorAll('.datepicker');
            var instances = M.Datepicker.init(elems, { 
                firstDay: true, 
                format: 'dd.mm.yyyy',
                i18n: {
                    months: ["Januar", "Februar", "Mart", "April", "Maj", "Jun", "Jul", "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"],
                    monthsShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],
                    weekdays: ["Nedjelja","Ponedjeljak", "Utorak", "Srijeda", "Četvrtak", "Petak", "Subota"],
                    weekdaysShort: ["Ned","Pon", "Uto", "Sri", "Čet", "Pet", "Sub"],
                    weekdaysAbbrev: ["N","P", "U", "S", "Č", "P", "S"]
                }
            });

            elems = document.querySelectorAll('select');
            instances = M.FormSelect.init(elems, {});
        });
    }

    openBlob(data, outType){
        var file; 
        if (outType == 'word'){
            file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
        }
        else
        {
            if (outType == 'pdf')
                file = new Blob([data], {type: 'application/pdf'});
            else 
                file = new Blob([data], {type: 'application/vnd.ms-excel'});    
        }
        var fileURL = URL.createObjectURL(file);
        //console.log(fileURL);
        window.open(fileURL);
    }

    downloadPdf(data, nazivFajla){
        var file; 
        file = new Blob([data], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        //console.log(fileURL);
        var link = document.createElement('a');
        link.href = fileURL;
        link.download = nazivFajla;
        link.click();
    }
}
