import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule,Routes} from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { GlobalService } from './global.service';
import { FooterComponent } from './footer/footer.component';
import { RegisterComponent } from './register/register.component';
import { LoaderComponent } from './loader/loader.component';
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { AuthInterceptor } from './app.interceptor';
import { UnosIzmenaPrijavaSkolaComponent } from './unos-izmena-prijava-skola/unos-izmena-prijava-skola.component';
import { ZakazivanjeComponent } from './zakazivanje/zakazivanje.component';
import { ForgetpassComponent } from './forgetpass/forgetpass.component';
import { ForgetPassSuccessComponent } from './forget-pass-success/forget-pass-success.component';
import { RulesComponent } from './rules/rules.component';
import { UnosPrijavaVrticComponent } from './unos-prijava-vrtic/unos-prijava-vrtic.component';
import { UnosIzmjenaPrviSrednjeComponent } from './unos-izmjena-prvi-srednje/unos-izmjena-prvi-srednje.component';
import { UnosIzmjenaDokumentaPrviSrednjeComponent } from './unos-izmjena-dokumenta-prvi-srednje/unos-izmjena-dokumenta-prvi-srednje.component';
import { UnosIzmjenaFakultetComponent } from './unos-izmjena-fakultet/unos-izmjena-fakultet.component';
import { TabelaOcjeneComponent } from './tabela-ocjene/tabela-ocjene.component';
import { UnosIzmjenaDokumentaFakultetComponent } from './unos-izmjena-dokumenta-fakultet/unos-izmjena-dokumenta-fakultet.component';
import { RangiranjeComponent } from './rangiranje/rangiranje.component';
import { RangiranjeDomComponent } from './rangiranje-dom/rangiranje-dom.component';
import { UnosIzmjenaPrijavaMuzickaComponent } from './unos-izmjena-prijava-muzicka/unos-izmjena-prijava-muzicka.component';
import { ZakazivanjeMuzickaComponent } from './zakazivanje-muzicka/zakazivanje-muzicka.component';
import { UnosIzmjenaDjackiDomComponent } from './unos-izmjena-djacki-dom/unos-izmjena-dom.component';
import { UgovorStudiranjeComponent } from './ugovor-studiranje/ugovor-studiranje.component';
//import {MatProgressBarModule} from '@angular/material/progress-bar';
import { UnosIzmjenaKreditComponent } from './unos-izmjena-kredit/unos-izmjena-kredit.component';
import { UnosIzmjenaStipComponent } from './unos-izmjena-stip/unos-izmjena-stip.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    FooterComponent,
    RegisterComponent,
    LoaderComponent,
    RegisterSuccessComponent,
    UnosIzmenaPrijavaSkolaComponent,
    ZakazivanjeComponent,
    ForgetpassComponent,
    ForgetPassSuccessComponent,
    RulesComponent,
    UnosPrijavaVrticComponent,
    UnosIzmjenaPrviSrednjeComponent,
    UnosIzmjenaDokumentaPrviSrednjeComponent,
    UnosIzmjenaFakultetComponent,
    TabelaOcjeneComponent,
    UnosIzmjenaDokumentaFakultetComponent,
    RangiranjeComponent,
    UnosIzmjenaPrijavaMuzickaComponent,
    ZakazivanjeMuzickaComponent, 
    UnosIzmjenaDjackiDomComponent, 
    RangiranjeDomComponent, UgovorStudiranjeComponent, 
    UnosIzmjenaKreditComponent, 
    UnosIzmjenaStipComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, 
    HttpClientModule,
    FormsModule],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor,multi: true }, GlobalService],
  bootstrap: [AppComponent]
})
export class AppModule { }
