import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'tabela-ocjene',
	templateUrl: './tabela-ocjene.component.html',
	styleUrls: ['./tabela-ocjene.component.css']
})
export class TabelaOcjeneComponent implements OnInit {
	@Input() ocjene = [];
	@Input() nazivRazreda; 
	@Input() prosjek;

	constructor() { }

	ngOnInit() {
	}

}
