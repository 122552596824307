import { Component, OnInit, ViewEncapsulation,ViewChild,Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import * as M from "materialize-css/dist/js/materialize";

@Component({
    selector: 'unos-izmjena-dokumenta-fakultet',
    templateUrl: './unos-izmjena-dokumenta-fakultet.component.html',
    styleUrls: ['./unos-izmjena-dokumenta-fakultet.component.css']
})
export class UnosIzmjenaDokumentaFakultetComponent implements OnInit {
    @Input() id;
	@Output() onInsertUspjeh = new EventEmitter<any>();
	@Output() onCancel = new EventEmitter<any>();
    loading = false;
	msg : any;
	prvi_predmet = '';
	drugi_predmet = '';
	novi = true;
    uspjeh : any = {ocjeneCetvrti : [], ocjeneTreci : [], ocjeneDrugi : [], ocjenePrvi : [], takmicenja : [], ocjeneEksterne : [], dodatnaNapomena : ''};
	bodovi : any = [];

    constructor(private http: HttpClient, public globalSrvc: GlobalService) { }

    ngOnInit() {
		scroll(0,0);
        this.ucitaj();

		setTimeout(() => {
			var elems = document.querySelectorAll('.tooltipped');
			var instances = M.Tooltip.init(elems, {});
		}, 1000);
    }

    ucitaj(){
		this.loading = true;
		this.http.get(this.globalSrvc.rootUrl +'prijave/fakultet/uspjeh/', { params: { id: this.id } })
		.subscribe(data => {
			this.loading = false;
			this.uspjeh = data;			
            //console.log("uspjeh", this.uspjeh);
			this.bodovi = [];
			this.bodovi.push(this.uspjeh);
		}
		,error => {
			this.loading = false;
		});
	}

	getLuca(){
		if (!this.uspjeh || !this.uspjeh.luca)
			return '';

		if (this.uspjeh.luca == 1)
			return 'I';
		
		if (this.uspjeh.luca == 2)
			return 'II';

		return '';
	}

	toastTimeout = 3000;

	savedata(){
		let url = 'prijave/fakultet/uspjeh/izmijeni';
		//this.prijavaskola.id = this.id; 

		this.loading = true;

		setTimeout(() => {				
		
			this.http.post(url, this.uspjeh)
				.subscribe(data => {
					this.msg = data;
					this.loading = false;

					if (this.msg.id == 1) {
						if (this.onInsertUspjeh)
							this.onInsertUspjeh.emit(this.uspjeh);
					}
					else {
						//this.globalSrvc.showErrorModal(this.msg.text);
						M.toast({html:this.msg.text, displayLength : this.toastTimeout});
					}
				}
				, error => {
					this.loading = false;
					//this.globalSrvc.showGenericErrorModal();
				}
			);
		}, 1000);	
	}

	cancel(){
		if (this.onCancel)
		
		
		this.onCancel.emit(null);
	}

	getBodoviLuca(luca){
		if (!luca)
		 	return '';
		
		if (luca == 1)
		 	return 5;
		if (luca == 2)
			return 3;

		return '';
	}

	getOcjenaExterna(extocjena){
		if (!extocjena)
		 	return '';
		
		return extocjena;
	}

	getBodoviTakmicenja(bodovi){
		if (bodovi === 0)
			return '';
		else 
			return bodovi;
	}
}
