import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalService } from './global.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as M from "materialize-css/dist/js/materialize";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'epis-front';
    rootUrl = ''
    isCollapsed = false;
    self = this;
    authenticated = false;
    public credentials = { username: 'k', pass: 'k' };
    user: any = {};
    loading = false;

    constructor(private http: HttpClient, public globalSrvc: GlobalService, private route: ActivatedRoute, public router: Router) { }

    ngOnInit(): void {
        let code = this.getParamValueQueryString('access_code');
        //console.log('code', code);
        if (code == null || code === undefined || code === undefined){
            code = this.getParamValueQueryString('elk_code');
            if (code)
                localStorage.setItem("acmetoken", code);

            this.getUser();
        }
        else
            this.confirmUser(code);

        //document.addEventListener('DOMContentLoaded', function () {
        setTimeout(() => {
            let elems = document.querySelectorAll('.tooltipped');
            let instances = M.Tooltip.init(elems, {});    
        }, 1000);
            
            
        //});

        //$('.datepicker').datepicker();
    }

    getParamValueQueryString(paramName) {
        const url = window.location.href;

        let paramValue;
        if (url.includes('?')) {
            const httpParams = new HttpParams({ fromString: url.split('?')[1] });
            //console.log(httpParams);
            paramValue = httpParams.get(paramName);
        }
        return paramValue;
    }

    getUser(): void {
        //this.globalSrvc.odabranoOdjeljenje = {};
        let idToken = localStorage.getItem("acmetoken");
        
        this.loading = true;

        setTimeout(() => {
            this.http.get(this.globalSrvc.rootUrl + 'user')
                .subscribe(data => {
                    //console.log('data from server', data);
                    this.user = data;
                    this.loading = false;
                    if (!this.user || this.user.username === undefined) {
                        this.globalSrvc.cleardata();
                        this.globalSrvc.redirectToLogin();
                    }
                    else {
                        this.globalSrvc.loadUser(this.user);
                        this.globalSrvc.redirectToHome();
                    }
                }
                    , error => {
                        this.loading = false;
                        this.globalSrvc.cleardata();
                        this.globalSrvc.redirectToLogin();
                    }
                );
        }, 1000);

    }

    confirmUser(code): void {
        //this.globalSrvc.odabranoOdjeljenje = {};

        this.loading = true;
        setTimeout(() => {

            this.http.post(this.globalSrvc.rootUrl + 'confirm_user', { code: code })
                .subscribe(data => {
                    this.user = data;
                    this.loading = false;
                    if (this.user.username === undefined) {
                        this.globalSrvc.cleardata();
                        this.globalSrvc.redirectToLogin();
                    }
                    else {
                        localStorage.setItem('acmetoken', this.user.token);
                        this.globalSrvc.loadUser(this.user);
                        this.globalSrvc.redirectToHome();
                    }
                }
                    , error => {
                        this.loading = false;
                        this.globalSrvc.cleardata();
                        this.globalSrvc.redirectToLogin();
                    }
                );
        }, 1000);
    }
}
