import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../global.service';
import { HttpClient } from '@angular/common/http';
import * as M from "materialize-css/dist/js/materialize";
import * as $ from 'jquery';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
	loading : boolean = false; 
	currentView = 'main';
	prijaveskola : any = [];
	prijavesrednjaskola : any = [];
	prijavepredskolsko : any = [];
	prijavefakultet : any = [];
	prijavemuzicka : any = [];
	prijavedomovi : any = [];
	prijavekrediti : any = [];
	prijavestip : any = [];
	odabranaPrijava : any = null;
	zakaziPrijava : any = null;
	zakaziPrijavaMuzicka : any = null;
	novaPrijavaSkola : boolean = true; 
	odabraniId : any = null;
	novaPrijavaVrtic : boolean = true;
	novaPrijavaPrviSrednje : boolean = true;  
	novaPrijavaFakultet : boolean = true;  
	novaPrijavaMuzicka : boolean = true;  
	novaPrijavaStip : boolean = true;  
	odabraniVrticPrijavaId : any = null;
	dodataPrijavaSkola : any = null;
	odabraniPrviSrednjeId : any = null;
	odabraniFakultetId : any = null;
	odabraniMuzickaId : any = null;
	odabranaPrijavaFakultet : any = null;
	odabranaPrijavaDom : any = null;
	odabranaPrijavaKredit : any = null;
	odabranaPrijavaMuzicka : any = null;
	odabranaPrijavaStip : any = null;
	novaPrijavaDjackiDom : any = true;
	novaPrijavaKredit : any = true;
	odabraniDomId :any = null;
	odabraniKreditId :any = null;
	odabranaStipId :any = null;
	odabraniDomIdTipa :any = null;
	novaPrijavaStudDomPrvaGodina : any = true;
	odabraniStudDomPrvaGodinaDomId :any = null;
	prijavaponisti : any = null;
	prijavafakultetponisti : any = null;
	prijavadomponisti : any = null;
	prijavakreditponisti : any = null;
	prijavastipponisti : any = null;
	msg : any = null;
	serverMsg : any = null; 

	constructor(public globalSrvc: GlobalService, public http : HttpClient) { }

	ngOnInit() {
		setTimeout(() => {
			/*var elems = document.querySelectorAll('.modal');
    		var instances = M.Modal.init(elems, {});
			var elems = document.querySelectorAll('.dropdown-trigger');
    		M.Dropdown.init(elems, {});*/
			let elems = document.querySelectorAll('.tooltipped');
            let instances = M.Tooltip.init(elems, {});    
		}, 1000);

		this.ucitajPrijave();	
	}

	ucitajPrijave(){
		this.loading = true; 
		setTimeout(() => {
			this.http.get(this.globalSrvc.rootUrl + 'prijave/pretraga', {params: {}})
			.subscribe(data => {
				//console.log(data);
				this.prijaveskola = data;
				this.prijavesrednjaskola = [];
				this.prijavefakultet = [];
				this.prijavemuzicka = [];
				this.prijavedomovi = [];
				this.prijavekrediti = [];
				this.prijavestip = [];
				//console.log(this.prijavestip);

				for(let i = 0; i < this.prijaveskola.length; i++)
					if (this.prijaveskola[i].tip == 'prvisrednje')
						this.prijavesrednjaskola.push(this.prijaveskola[i]);
				
				for(let i = 0; i < this.prijavesrednjaskola.length; i++){
					let indx = this.prijaveskola.indexOf(this.prijavesrednjaskola[i]);
					if (indx != -1)
						this.prijaveskola.splice(indx, 1);
				}

				for(let i = 0; i < this.prijaveskola.length; i++)
					if (this.prijaveskola[i].tip == 'fakultet')
						this.prijavefakultet.push(this.prijaveskola[i]);
				
				for(let i = 0; i < this.prijavefakultet.length; i++){
					let indx = this.prijaveskola.indexOf(this.prijavefakultet[i]);
					if (indx != -1)
						this.prijaveskola.splice(indx, 1);
				}

				this.prijavepredskolsko = [];
				for(let i = 0; i < this.prijaveskola.length; i++)
					if (this.prijaveskola[i].tip == 'vrtic')
						this.prijavepredskolsko.push(this.prijaveskola[i]);
				
				for(let i = 0; i < this.prijavepredskolsko.length; i++){
					let indx = this.prijaveskola.indexOf(this.prijavepredskolsko[i]);
					if (indx != -1)
						this.prijaveskola.splice(indx, 1);
				}

				for(let i = 0; i < this.prijaveskola.length; i++)
					if (this.prijaveskola[i].tip == 'muzicka')
						this.prijavemuzicka.push(this.prijaveskola[i]);

				for(let i = 0; i < this.prijavemuzicka.length; i++){
					let indx = this.prijaveskola.indexOf(this.prijavemuzicka[i]);
					if (indx != -1)
						this.prijaveskola.splice(indx, 1);
				}

				for(let i = 0; i < this.prijaveskola.length; i++)
					if (this.prijaveskola[i].tip == 'dom')
						this.prijavedomovi.push(this.prijaveskola[i]);

				for(let i = 0; i < this.prijavedomovi.length; i++){
					let indx = this.prijaveskola.indexOf(this.prijavedomovi[i]);
					if (indx != -1)
						this.prijaveskola.splice(indx, 1);
				}

				for(let i = 0; i < this.prijaveskola.length; i++)
					if (this.prijaveskola[i].tip == 'kredit')
						this.prijavekrediti.push(this.prijaveskola[i]);

				for(let i = 0; i < this.prijavekrediti.length; i++){
					let indx = this.prijaveskola.indexOf(this.prijavekrediti[i]);
					if (indx != -1)
						this.prijaveskola.splice(indx, 1);
				}

				for(let i = 0; i < this.prijaveskola.length; i++)
					if (this.prijaveskola[i].tip == 'stip')
						this.prijavestip.push(this.prijaveskola[i]);

				for(let i = 0; i < this.prijavestip.length; i++){
					let indx = this.prijaveskola.indexOf(this.prijavestip[i]);
					if (indx != -1)
						this.prijaveskola.splice(indx, 1);
				}
				//console.log(this.prijavedomovi);
				this.loading = false;

				setTimeout(() => {
					let elems = document.querySelectorAll('.tooltipped');
            		let instances = M.Tooltip.init(elems, {});    
				}, 1000);
			},
			error => {
				this.loading = false;
			});	
		}, 1000);
	}

	logout(){
		this.globalSrvc.logout();
		this.globalSrvc.redirectToLogin();
	}

	initTooltips(){
		setTimeout(() => {
			let elems = document.querySelectorAll('.tooltipped');
            let instances = M.Tooltip.init(elems, {});    
		}, 500);
	}

	podnesiZahjetvSkola(){
		this.loading = true;
		try 
		{
			this.http.get('prijave/deadline', { params: { idRoka : "11" } })
			.subscribe(data => {
				this.loading = false;
				this.msg = data; 

				if (this.msg.id == 1) {
					this.novaPrijavaSkola = true;
					this.odabraniId = null;
					this.currentView = 'skola';
				}
				else {
					//this.globalSrvc.showErrorModal(this.msg.text);
					//M.toast({html:this.msg.text, displayLength : 4000});
					this.serverMsg = this.msg.text;
					var elem = document.getElementById('modal12');				
					var instance = M.Modal.init(elem, {});
					instance.open();
				}
			}
			, error => {		
				this.loading = false;
			});
		}
		catch(err){
			console.log(err);
		}
		
	}

	checkDomDealine(data){
		this.podnesiZahtjevDealine(data.idRoka, data.callbackFunc);
	}

	podnesiZahtjevDealine(idRoka, podnesiZahtjevFunc){
		this.loading = true;
		try 
		{
			this.http.get('prijave/deadline', { params: { idRoka : idRoka } })
			.subscribe(data => {
				this.loading = false;
				this.msg = data; 

				if (this.msg.id == 1) {
					podnesiZahtjevFunc();
				}
				else {
					this.serverMsg = this.msg.text;
					var elem = document.getElementById('modal12');				
					var instance = M.Modal.init(elem, {});
					this.currentView = 'main';
					instance.open();
					this.initTooltips();
				}
			}
			, error => {		
				this.loading = false;
			});
		}
		catch(err){
			console.log(err);
		}
		
	}

	podnesiZahtjevDealineFakultet(podnesiZahtjevFunc){
		this.loading = true;
		try 
		{
			this.http.get('prijavefakultet/deadline', {})
			.subscribe(data => {
				this.loading = false;
				this.msg = data; 

				if (this.msg.id == 1) {
					podnesiZahtjevFunc();
				}
				else {
					this.serverMsg = this.msg.text;
					var elem = document.getElementById('modal12');				
					var instance = M.Modal.init(elem, {});
					instance.open();
				}
			}
			, error => {		
				this.loading = false;
			});
		}
		catch(err){
			console.log(err);
		}
		
	}

	podnesiZahjevVrtic(){
		this.podnesiZahtjevDealine("10", this.podnesiZahjevVrticFunc);
	}

	podnesiZahjevVrticFunc  = () => {
		this.novaPrijavaVrtic = true;
		this.odabraniVrticPrijavaId = null;
		this.currentView = 'vrtic';
	}

	podnesiZahjevPrviSrednje(){
		this.podnesiZahtjevDealine("1", this.podnesiZahjevPrviSrednjeFunc);
	}

	podnesiZahjevPrviSrednjeFunc = () => {
		this.novaPrijavaPrviSrednje = true;
		this.odabraniPrviSrednjeId = null;
		this.currentView = 'prvisrednje';
	}

	podnesiZahjevFakultet(){
		this.podnesiZahtjevDealineFakultet(this.podnesiZahjevFakultetFunc);
	}
	
	podnesiZahjevFakultetFunc = () => {
		this.novaPrijavaFakultet = true;
		this.odabraniFakultetId = null;
		this.currentView = 'fakultet';
	}

	podnesiZahjevMuzicka(){
		this.podnesiZahtjevDealine("13", this.podnesiZahjevMuzickaFunc);
	}

	podnesiZahjevMuzickaFunc = () => {
		this.novaPrijavaMuzicka = true;
		this.odabraniMuzickaId = null;
		this.currentView = 'muzicka';
	}

	podnesiPrijavuDom(){
		//this.podnesiZahtjevDealine("20", this.podnesiPrijavuDjackiDomFunc);
		this.novaPrijavaDjackiDom = true;
		this.odabraniDomId = null;
		this.currentView = 'dom';
	}

	podnesiPrijavuKredit(){
		this.podnesiZahtjevDealine("30", this.podnesiPrijavuKreditFunc);
	}

	podnesiPrijavuStip(){
		this.podnesiZahtjevDealine("31", this.podnesiPrijavuStipFunc);
	}

	podnesiPrijavuKreditFunc  = () => {
		this.novaPrijavaKredit = true;
		this.odabraniKreditId = null;
		this.currentView = 'kredit';
	}

	podnesiPrijavuStipFunc  = () => {
		this.novaPrijavaStip = true;
		this.odabranaPrijavaStip = null;
		this.currentView = 'stip';
	}

	podnesiPrijavuDjackiDomFunc = () => {
	}

	podnesiPrijavuStudDomIGodina(){
		this.podnesiZahtjevDealine("21", this.podnesiPrijavuStudDomIGodina);
	}

	podnesiPrijavuStudDomIGodinaFunc = () => {
		this.novaPrijavaStudDomPrvaGodina = true;
		this.odabraniStudDomPrvaGodinaDomId = null;
		this.currentView = 'studdomprvagodina';
	}

	pregleddokumenta(prijava){
		this.odabraniPrviSrednjeId = prijava.id;
		this.currentView = 'dokumentaprvi';
		prijava.uspjehDef = '1';
	}

	pregleddokumentafakultet(prijava){
		this.odabraniFakultetId = prijava.id;
		this.currentView = 'dokumentafakultet';
		prijava.uspjehDef = '1';
	}
	
	pregledBodovanja(prijava){
		this.odabraniFakultetId = prijava.id;
		this.currentView = 'rangfakultet';
	}

	pregledBodovanjaDom(prijava){
		this.odabraniDomId = prijava.id;
		this.odabraniDomIdTipa = prijava.idTipaPrijave;
		this.currentView = 'rangdom';
	}

	pregledprijavadom(prijava){
		this.odabraniDomId = prijava.id; 
		this.currentView = 'dom';
		prijava.uspjehDef = '1';
	}

	pregledprijavakredit(prijava){
		this.odabraniKreditId = prijava.id; 
		this.currentView = 'kredit';
	}
	
	pregledprijavastip(prijava){
		this.odabranaPrijavaStip = prijava.id; 
		this.currentView = 'stip';
		prijava.uspjehDef = '1';
	}

	ugovorFakultet (prijava){
		this.odabraniFakultetId = prijava.id;
		this.currentView = 'ugovorfakulet';
	}

	onInsertSkola(data){
		this.novaPrijavaSkola = data;
		this.currentView = 'main';
		var elem = document.getElementById('modal1');				
		var instance = M.Modal.init(elem, {onCloseEnd : () => {this.ucitajPrijave();}});
		instance.open();
		this.initTooltips();
	}
	
	onInsertMuzSkola(data){
		//this.novaPrijavaSkola = data;
		this.currentView = 'main';
		var elem = document.getElementById('modal11');				
		var instance = M.Modal.init(elem, {onCloseEnd : () => {this.ucitajPrijave();}});
		instance.open();
		this.initTooltips();
	}

	onInsertPrviSrednje(data){
		this.currentView = 'main';
		var elem = document.getElementById('modal6');				
		var instance = M.Modal.init(elem, {onCloseEnd : () => {this.ucitajPrijave();}});
		instance.open();
		this.initTooltips();
	}
	
	onInsertFakultet(data){
		this.currentView = 'main';
		var elem = document.getElementById('modal8');				
		var instance = M.Modal.init(elem, {onCloseEnd : () => {this.ucitajPrijave();}});
		instance.open();
		this.initTooltips();
	}

	onInsertDom(data){
		//console.log(data);
		this.currentView = 'main';
		var elem = document.getElementById('modal14');				

		if (data.idTipaPrijave == '5' || data.idTipaPrijave == '6')
			elem = document.getElementById('modal15');				

		var instance = M.Modal.init(elem, {onCloseEnd : () => {this.ucitajPrijave();}});
		instance.open();
		this.initTooltips();
	}

	onInsertKredit(data){
		//console.log(data);
		this.currentView = 'main';
		var elem = document.getElementById('modal16');	
		var instance = M.Modal.init(elem, {onCloseEnd : () => {this.ucitajPrijave();}});
		instance.open();
		this.initTooltips();
	}

	onInsertStip(data){
		//console.log(data);
		this.currentView = 'main';
		var elem = document.getElementById('modal18');	
		var instance = M.Modal.init(elem, {onCloseEnd : () => {this.ucitajPrijave();}});
		instance.open();
		this.initTooltips();
	}

	onInsertUpsjeh(data){
		let item = this.prijavesrednjaskola.find((item) => item.id == data.id);
		
		if (item)
			item.uspjehDef = '1';

		this.currentView = 'main';
		var elem = document.getElementById('modal2');				
		var instance = M.Modal.init(elem, {});
		instance.open();
		this.initTooltips();
	}

	onInsertUspjehFakultet(data){
		let item = this.prijavefakultet.find((item) => item.id == data.id);
		
		if (item)
			item.uspjehDef = '1';

		this.currentView = 'main';
		var elem = document.getElementById('modal10');				
		var instance = M.Modal.init(elem, {});
		instance.open();
		this.initTooltips();
	}

	onNoTermin(){
		this.currentView = 'main';
		var elem = document.getElementById('modal5');				
		var instance = M.Modal.init(elem, {});
		instance.open();
		this.initTooltips();
	}

	onInsertVrtic(event){
		this.currentView = 'main';
		var elem = document.getElementById('modal2');				
		var instance = M.Modal.init(elem, {onCloseEnd : () => {this.ucitajPrijave();}});
		instance.open();
		this.initTooltips();
	}

	onZakazi(data){
		this.currentView = 'main';
		var elem = document.getElementById('modal2');				
		var instance = M.Modal.init(elem, {onCloseEnd : () => {this.ucitajPrijave();}});
		instance.open();
		this.initTooltips();
	}

	/*onZakaziMuzicka(data){
		this.currentView = 'main';
		var elem = document.getElementById('modal2');				
		var instance = M.Modal.init(elem, {onCloseEnd : () => {this.ucitajPrijave();}});
		instance.open();
	}*/

	onCancel(data){
		this.currentView = 'main';
		this.initTooltips();
	}

	zakazi(prijava){
		this.zakaziPrijava = prijava;
		this.currentView = 'zakazi';
	}
	
	zakazimuzicka(prijava){
		this.zakaziPrijavaMuzicka = prijava;
		this.currentView = 'zakazimuzicka';
	}

	pregled(prijava){
		if (prijava.tip == 'skola'){
			this.novaPrijavaSkola = false;
			this.odabraniId = prijava.id;
			this.currentView = 'skola';
		}
		else if (prijava.tip == 'prvisrednje'){
			this.novaPrijavaPrviSrednje = false;
			this.odabraniPrviSrednjeId = prijava.id;
			this.currentView = 'prvisrednje';
		}
		else if (prijava.tip == 'fakultet'){
			this.novaPrijavaFakultet = false;
			this.odabraniFakultetId = prijava.id;
			this.currentView = 'fakultet';
		}
		else if (prijava.tip == 'muzicka'){
			this.novaPrijavaMuzicka = false;
			this.odabraniMuzickaId = prijava.id;
			this.currentView = 'muzicka';
		}
		else {
			this.novaPrijavaVrtic = false;
			this.odabraniVrticPrijavaId = prijava.id;
			this.currentView = 'vrtic';
		}
	}

	handleSvj9LiceFileChange = (event) => {
		let files = event.target.files;

		if (FileReader && files && files.length) {
	        let fr = new FileReader();
	        fr.onload = () => {
	            console.log(fr.result); 	            
	        }
	        fr.readAsDataURL(files[0]);
	    }
	}

	ponistiprijavu(prijava){
		this.prijavaponisti = prijava; 
		this.currentView = 'main';
		var elem = document.getElementById('modal7');				
		var instance = M.Modal.init(elem, {onCloseEnd : () => {}});
		instance.open();
	}

	ponistiprijavufakultet(prijava){
		this.prijavafakultetponisti = prijava; 
		this.currentView = 'main';
		var elem = document.getElementById('modal9');				
		var instance = M.Modal.init(elem, {onCloseEnd : () => {}});
		instance.open();
	}

	ponistiprijavudom(prijava){
		this.prijavadomponisti = prijava; 
		this.currentView = 'main';
		var elem = document.getElementById('modal13');				
		var instance = M.Modal.init(elem, {onCloseEnd : () => {}});
		instance.open();
	}

	ponistiprijavukredit(prijava){
		this.prijavakreditponisti = prijava; 
		this.currentView = 'main';
		var elem = document.getElementById('modal17');				
		var instance = M.Modal.init(elem, {onCloseEnd : () => {}});
		instance.open();
	}

	ponistiprijavustip(prijava){
		this.prijavastipponisti = prijava; 
		this.currentView = 'main';
		var elem = document.getElementById('modal19');				
		var instance = M.Modal.init(elem, {onCloseEnd : () => {}});
		instance.open();
	}

	ponistiPrijavuPotvrda(){
		let url = 'prijave/srednja/ponisti';

		this.loading = true;

		setTimeout(() => {				
		
			this.http.post(url, this.prijavaponisti)
				.subscribe(data => {
					this.msg = data;
					this.loading = false;

					if (this.msg.id == 1) {
						this.ucitajPrijave();
					}
					else {
						//this.globalSrvc.showErrorModal(this.msg.text);
						M.toast({html:this.msg.text, displayLength : 4000});
					}
				}
				, error => {
					this.loading = false;
					//this.globalSrvc.showGenericErrorModal();
				}
			);
		}, 1000);
	}

	ponistiPrijavuFakultetPotvrda(){
		let url = 'prijave/fakultet/ponisti';

		this.loading = true;
		console.log('test', this.prijavafakultetponisti);

		setTimeout(() => {							
			this.http.post(url, this.prijavafakultetponisti)
				.subscribe(data => {
					this.msg = data;
					this.loading = false;

					if (this.msg.id == 1) {
						this.ucitajPrijave();
					}
					else {
						//this.globalSrvc.showErrorModal(this.msg.text);
						M.toast({html:this.msg.text, displayLength : 4000});
					}
				}
				, error => {
					this.loading = false;
					//this.globalSrvc.showGenericErrorModal();
				}
			);
		}, 1000);
	}

	ponistiPrijavuDomPotvrda(){
		let url = 'prijave/dom/ponisti';

		this.loading = true;
		
		setTimeout(() => {				
		
			this.http.post(url, this.prijavadomponisti)
				.subscribe(data => {
					this.msg = data;
					this.loading = false;

					if (this.msg.id == 1) {
						this.ucitajPrijave();
					}
					else {
						//this.globalSrvc.showErrorModal(this.msg.text);
						M.toast({html:this.msg.text, displayLength : 4000});
					}
				}
				, error => {
					this.loading = false;
					//this.globalSrvc.showGenericErrorModal();
				}
			);
		}, 1000);
	}

	ponistiPrijavuKreditPotvrda(){
		let url = 'prijave/kredit/ponisti';

		this.loading = true;
		
		setTimeout(() => {				
		
			this.http.post(url, this.prijavakreditponisti)
				.subscribe(data => {
					this.msg = data;
					this.loading = false;

					if (this.msg.id == 1) {
						this.ucitajPrijave();
					}
					else {
						//this.globalSrvc.showErrorModal(this.msg.text);
						M.toast({html:this.msg.text, displayLength : 4000});
					}
				}
				, error => {
					this.loading = false;
					//this.globalSrvc.showGenericErrorModal();
				}
			);
		}, 1000);
	}

	ponistiPrijavuStipPotvrda(){
		let url = 'prijave/stip/ponisti';

		this.loading = true;
		
		setTimeout(() => {				
		
			this.http.post(url, this.prijavastipponisti)
				.subscribe(data => {
					this.msg = data;
					this.loading = false;

					if (this.msg.id == 1) {
						this.ucitajPrijave();
					}
					else {
						//this.globalSrvc.showErrorModal(this.msg.text);
						M.toast({html:this.msg.text, displayLength : 4000});
					}
				}
				, error => {
					this.loading = false;
					//this.globalSrvc.showGenericErrorModal();
				}
			);
		}, 1000);
	}
}
