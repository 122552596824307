import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import * as M from "materialize-css/dist/js/materialize";

@Component({
	selector: 'unos-izmjena-kredit',
	templateUrl: './unos-izmjena-kredit.component.html',
	styleUrls: ['./unos-izmjena-kredit.component.css']
})
export class UnosIzmjenaKreditComponent implements OnInit {

	constructor(private http: HttpClient, public globalSrvc: GlobalService) { }

	@Input() id;
	@Input() novi;

	@Output() onInsertKredit = new EventEmitter<any>();
	@Output() onCancel = new EventEmitter<any>();

	prijavakredit : any = {id: '', statusPrijave: '', kreditPretGodine: '', drzavljaninCG: '', drzava: '', studiraCG: '', drzavaStud: '', jmbg: '', ime: '', prezime: '', 
	imeRoditelja: '', datumRodjenja: '', pol: '', opstina: '', mjestoPrebivalista: '', ulicaBroj: '', brojPoste: '', telefon: '', brojLK: '', mjestoLK: '', 
	idFakulteta: '', nazivFakulteta: '', nazivStudProg: '', nivoStudija: '', godina: '', indexUspjeha: '', brojIndeksa: '', redniBrojUpisa : '', napomena : ''};

	err_kreditPretGodine = false;
	err_kreditPretGodine_poruka = '';
	err_drzavljaninCG = false;
	err_drzavljaninCG_poruka = '';
	err_drzava = false;
	err_drzava_poruka = '';
	err_studiraCG = false;
	err_studiraCG_poruka = '';
	err_drzavaStud = false;
	err_drzavaStud_poruka = '';
	err_jmbg = false;
	err_jmbg_poruka = '';
	err_ime = false;
	err_ime_poruka = '';
	err_prezime = false;
	err_prezime_poruka = '';	
	err_imeOca = false;
	err_imeOca_poruka = '';
	err_imeMajke = false;
	err_imeMajke_poruka = '';
	err_datumRodjenja = false;
	err_datumRodjenja_poruka = '';
	err_pol = false;
	err_pol_poruka = '';
	err_opstina = false;
	err_opstina_poruka = '';
	err_mjestoPrebivalista = false;
	err_mjestoPrebivalista_poruka = '';
	err_ulicaBroj = false;
	err_ulicaBroj_poruka = '';
	err_brojPoste = false;
	err_brojPoste_poruka = '';
	err_telefon = false;
	err_telefon_poruka = '';
	err_brojLK = false;
	err_brojLK_poruka = '';
	err_mjestoLK = false;
	err_mjestoLK_poruka = '';
	err_idFakulteta = false;
	err_idFakulteta_poruka = '';
	err_nazivFakulteta = false;
	err_nazivFakulteta_poruka = '';
	err_nazivStudProg = false; 
	err_nazivStudProg_poruka = '';
	err_nivoStudija = false; 
	err_nivoStudija_poruka = '';
	err_godina = false; 
	err_godina_poruka = '';
	err_indexUspjeha = false; 
	err_indexUspjeha_poruka = '';
	err_brojIndeksa = false; 
	err_brojIndeksa_poruka = '';
	err_redniBrojUpisa = false; 
	err_redniBrojUpisa_poruka = '';

	saveDisabled = false;
	loading = false;
	msg : any;
	stepen : any = null;

	fakulteti : any = [];
	fakulteti_svi : any = [];
	poste : any = [];
	godine : any = [{id:'1', naziv : '1'}, {id:'2', naziv : '2'}, {id:'3', naziv : '3'}, {id:'4', naziv : '4'}, {id:'5', naziv : '5'}, {id:'6', naziv : '6'}];
	nivoiStudija : any = [{id:null, naziv : ''}, {id:'O', naziv : 'Osnovne'}, {id:'M', naziv : 'Master'}];
	fakultet_podaci_ws = false; 
	
	ngOnInit() {
		window.scroll(0, 0);
		this.novi = this.id?false:true; 
		//console.log(this.novi);
		this.ucitaj();

		let elems = document.querySelectorAll('.tooltipped');
		let instances = M.Tooltip.init(elems, {});
	}

	ucitaj() {
		this.loading = true;
		this.http.get('prijave/traziprijavukredit', { params: { id: this.id} })
			.subscribe(data => {
				this.loading = false;
				this.prijavakredit = data;	
				
				//console.log('this.prijavafakultet', this.prijavakredit);
				this.fakulteti = this.prijavakredit.fakulteti;
				this.fakulteti_svi = [...this.fakulteti];
				this.poste = this.prijavakredit.poste;
				//this.ucitajSkole();
				this.prijavakredit.fakulteti = [];
				this.prijavakredit.poste = [];

				if (this.novi){
					this.prijavakredit.drzavljaninCG = '1';
					this.prijavakredit.drzava = 'Crna Gora';
					this.prijavakredit.studiraCG = '1';
					this.prijavakredit.drzavaStud = 'Crna Gora';
				}
				setTimeout(() => {
					M.updateTextFields();
				}, 300);
			}
			, error => {
				this.loading = false;
			});
	}

	postaviDrzavljaninCG(value){
		this.prijavakredit.drzavljaninCG = value;
		if (value === '1'){
			this.prijavakredit.drzava = 'Crna Gora';
			//this.jmbgChanged();
		}
		else {
			this.prijavakredit.drzava = '';
		}

		setTimeout(() => {
			M.updateTextFields();
		}, 100);
	}

	postaviStudiraCG(value){
		this.prijavakredit.studiraCG = value;
		if (value === '1'){
			this.prijavakredit.drzavaStud = 'Crna Gora';
		}
		else {
			this.prijavakredit.drzavaStud = '';
		}
		setTimeout(() => {
			M.updateTextFields();
		}, 100);
	}

	getNivoStudija(){
		if (this.prijavakredit.nivoStudija == 'O')
			return 'Osnovne';
		else if (this.prijavakredit.nivoStudija == 'M')
			return 'Master';
	}

	getUstanova(){
		if (this.prijavakredit.srednjeSkole && this.prijavakredit.srednjeSkole.length > 0)
			return this.prijavakredit.srednjeSkole[0].naziv;
	}

	getFakultet(){
		if (this.prijavakredit.fakulteti && this.prijavakredit.fakulteti.length > 0){
			//console.log('fakulteti', this.prijavadom.fakulteti[0].naziv);
			return this.prijavakredit.fakulteti[0].naziv;
		}
	}

	jmbgfocuslost(event){
		//console.log('focus lost', this.prijavaskola.jmbg);
	}

	toastTimeout = 3000;

	savedata() {
		try {
			if (!this.prijavakredit.kreditPretGodine) {
				this.err_kreditPretGodine = true;
				this.err_kreditPretGodine_poruka = 'Morate definisati polje Student je već primao kredit ranijih godina?';
				M.toast({html: this.err_kreditPretGodine_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_kreditPretGodine = false;
			this.err_kreditPretGodine_poruka = '';

			if (!this.prijavakredit.drzavljaninCG) {
				this.err_drzavljaninCG = true;
				this.err_drzavljaninCG_poruka = 'Morate definisati polje Student je državljanin Crne Gore?';
				M.toast({html: this.err_drzavljaninCG_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_drzavljaninCG = false;
			this.err_drzavljaninCG_poruka = '';

			if (!this.prijavakredit.drzava) {
				this.err_drzava = true;
				this.err_drzava_poruka = 'Morate definisati polje Naziv države studenta?';
				M.toast({html: this.err_drzava_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_drzava = false;
			this.err_drzava_poruka = '';

			if (!this.prijavakredit.studiraCG) {
				this.err_studiraCG = true;
				this.err_studiraCG_poruka = 'Morate definisati polje Student studira u Crnoj Gori?';
				M.toast({html: this.err_studiraCG_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_studiraCG = false;
			this.err_studiraCG_poruka = '';

			if (!this.prijavakredit.drzavaStud) {
				this.err_drzavaStud = true;
				this.err_drzavaStud_poruka = 'Morate definisati polje Naziv države studiranja.';
				M.toast({html: this.err_drzavaStud_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_drzavaStud = false;
			this.err_drzavaStud_poruka = '';

			if (!this.prijavakredit.jmbg) {
				this.err_jmbg = true;
				this.err_jmbg_poruka = 'Morate definisati polje JMB (identifikacioni broj).';
				M.toast({html: this.err_jmbg_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_jmbg = false;
			this.err_jmbg_poruka = '';

			if (!this.prijavakredit.ime) {
				this.err_ime = true;
				this.err_ime_poruka = 'Morate definisati polje Ime.';
				M.toast({html: this.err_ime_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_ime = false;
			this.err_ime_poruka = '';

			if (!this.prijavakredit.prezime) {
				this.err_prezime = true;
				this.err_prezime_poruka = 'Morate definisati polje Prezime.';
				M.toast({html: this.err_prezime_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_prezime = false;
			this.err_prezime_poruka = '';
			
			if (!this.prijavakredit.datumRodjenja) {
				this.err_datumRodjenja = true;
				this.err_datumRodjenja_poruka = 'Morate definisati polje Datum rođenja.';
				M.toast({html: this.err_datumRodjenja_poruka, displayLength : this.toastTimeout});
				return;
			}

			if (!this.globalSrvc.CheckDate(this.prijavakredit.datumRodjenja)) {
				this.err_datumRodjenja = true;
				this.err_datumRodjenja_poruka = 'Nevalidan format za polje Datum rođenja.';
				M.toast({html: this.err_datumRodjenja_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_datumRodjenja = false;
			this.err_datumRodjenja_poruka = '';
			
			if (!this.prijavakredit.pol) {
				this.err_pol = true;
				this.err_pol_poruka = 'Morate definisati polje Pol.';
				M.toast({html: this.err_pol_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_pol = false;
			this.err_pol_poruka = '';

			if (!this.prijavakredit.opstina) {
				this.err_opstina = true;
				this.err_opstina_poruka = 'Morate definisati polje Opština.';
				M.toast({html: this.err_opstina_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_opstina = false;
			this.err_opstina_poruka = '';

			/*
			if (!this.prijavakredit.mjestoPrebivalista) {
				this.err_mjestoPrebivalista = true;
				this.err_mjestoPrebivalista_poruka = 'Morate definisati polje Mjesto prebivališta.';
				M.toast({html: this.err_mjestoPrebivalista_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_mjestoPrebivalista = false;
			this.err_mjestoPrebivalista_poruka = '';
			*/
			if (!this.prijavakredit.brojPoste) {
				this.err_brojPoste = true;
				this.err_brojPoste_poruka = 'Morate definisati polje Broj pošte.';
				M.toast({html: this.err_brojPoste_poruka, displayLength : this.toastTimeout});
				return;
			}

			/*if (!this.globalSrvc.CheckPostalNum(this.prijavakredit.brojPoste)) {
				this.err_brojPoste = true;
				this.err_brojPoste_poruka = 'Polje Broj pošte mora imati tačno 5 cifara.';
				M.toast({html: this.err_brojPoste_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_brojPoste = false;
			this.err_brojPoste_poruka = '';*/

			if (!this.prijavakredit.telefon){
				this.err_telefon = true;
				this.err_telefon_poruka = 'Morate definisati polje Broj telefona';
				M.toast({html: this.err_telefon_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_telefon = false;
			this.err_telefon_poruka = '';

			if (!this.prijavakredit.brojLK){
				this.err_brojLK = true;
				this.err_brojLK_poruka = 'Morate definisati polje Reg. broj lične karte';
				M.toast({html: this.err_brojLK_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_brojLK = false;
			this.err_brojLK_poruka = '';

			if (!this.prijavakredit.mjestoLK){
				this.err_mjestoLK = true;
				this.err_mjestoLK_poruka = 'Morate definisati polje Mjesto izdavanja lične karte';
				M.toast({html: this.err_mjestoLK_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_mjestoLK = false;
			this.err_mjestoLK_poruka = '';
			
			if (this.prijavakredit.studiraCG == '1'){
				if (!this.prijavakredit.idFakulteta){
					this.err_idFakulteta = true;
					this.err_idFakulteta_poruka = 'Morate definisati polje Ustanova - fakultet';
					M.toast({html: this.err_idFakulteta_poruka, displayLength : this.toastTimeout});
					return;
				}
				this.err_idFakulteta = false;
				this.err_idFakulteta_poruka = '';
			}
			else {
				if (!this.prijavakredit.nazivFakulteta){
					this.err_nazivFakulteta = true;
					this.err_nazivFakulteta_poruka = 'Morate definisati polje Fakultet';
					M.toast({html: this.err_nazivFakulteta_poruka, displayLength : this.toastTimeout});
					return;
				}
				this.err_nazivFakulteta = false;
				this.err_nazivFakulteta_poruka = '';
			}

			if (!this.prijavakredit.nazivStudProg){
				this.err_nazivStudProg = true;
				this.err_nazivStudProg_poruka = 'Morate definisati polje Naziv studijskog programa';
				M.toast({html: this.err_nazivStudProg_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_nazivStudProg = false;
			this.err_nazivStudProg_poruka = '';

			if (!this.prijavakredit.nivoStudija){
				this.err_nivoStudija = true;
				this.err_nivoStudija_poruka = 'Morate definisati polje Vrsta studija';
				M.toast({html: this.err_nivoStudija_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_nivoStudija = false;
			this.err_nivoStudija_poruka = '';

			if (!this.prijavakredit.godina){
				this.err_godina = true;
				this.err_godina_poruka = 'Morate definisati polje Godina';
				M.toast({html: this.err_godina_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_godina = false;
			this.err_godina_poruka = '';

			if (!this.fakultet_podaci_ws && !this.prijavakredit.indexUspjeha){
				this.err_indexUspjeha = true;
				this.err_indexUspjeha_poruka = 'Indeks uspjeha/Prosječna ocjena*';
				M.toast({html: this.err_indexUspjeha_poruka, displayLength : this.toastTimeout});
				return;
			}

			if (this.prijavakredit.indexUspjeha && !this.globalSrvc.CheckNumber(this.prijavakredit.indexUspjeha)){
				this.err_indexUspjeha = true;
				this.err_indexUspjeha_poruka = 'Nevalidan format za polje Indeks uspjeha ostvaren u prethodnoj studijskoj godini. Zadajte broj na najviše dvije decimale. ';
				M.toast({html: this.err_indexUspjeha_poruka, displayLength : this.toastTimeout});
				return;
			}

			this.err_indexUspjeha = false;
			this.err_indexUspjeha_poruka = '';

			if (!this.prijavakredit.redniBrojUpisa){
				this.err_redniBrojUpisa = true;
				this.err_redniBrojUpisa_poruka = 'Redni broj upisa*';
				M.toast({html: this.err_redniBrojUpisa_poruka, displayLength : this.toastTimeout});
				return;
			}

			if (!this.globalSrvc.CheckInt(this.prijavakredit.redniBrojUpisa)){
				this.err_redniBrojUpisa = true;
				this.err_redniBrojUpisa_poruka = 'Nevalidan format za polje Redni broj upisa.';
				M.toast({html: this.err_redniBrojUpisa_poruka, displayLength : this.toastTimeout});
				return;
			}

			this.err_indexUspjeha = false;
			this.err_indexUspjeha_poruka = '';

			/*if (!this.prijavakredit.brojIndeksa){
				this.err_brojIndeksa = true;
				this.err_brojIndeksa_poruka = 'Morate definisati polje Broj indeksa';
				M.toast({html: this.err_brojIndeksa_poruka, displayLength : this.toastTimeout});
				return;
			}
			this.err_brojIndeksa = false;
			this.err_brojIndeksa_poruka = '';*/

			let url = 'prijave/kredit/dodajprijavu';
			if (!this.novi)
				url = 'prijave/kredit/izmijeniprijavu';
				
			
			this.loading = true;			
			setTimeout(() => {		
				this.http.post(url, this.prijavakredit)
					.subscribe(data => {
						this.msg = data;
						this.loading = false;

						if (this.msg.id == 1) {
							if (this.prijavakredit.id === undefined || this.prijavakredit.id == null || this.prijavakredit.id == '') {
								this.prijavakredit.id = this.msg.newId;
							}
							//this.activeModal.close(this.prijavaskola);
							//this.globalSrvc.showSuccessModal();
							//M.toast({html : 'Uspješno', displayLength : this.toastTimeout});

							if (this.onInsertKredit)
								this.onInsertKredit.emit(this.prijavakredit);
						}
						else {
							//this.globalSrvc.showErrorModal(this.msg.text);
							M.toast({html:this.msg.text, displayLength : this.toastTimeout});
						}
					}
					, error => {
						this.loading = false;
						//this.globalSrvc.showGenericErrorModal();
					}
				);
			}, 1000);			
		}
		catch (err) {

		}
	}
	crps_data: any = null;

	centralniregistar() {
		this.loading = true;

		setTimeout(() => {			
		
			this.http.get(this.globalSrvc.rootUrl + 'prijave/krediti/registri/pretraga/jmbg/', { params: { jmbg: this.prijavakredit.jmbg, studiraCG : this.prijavakredit.studiraCG} })
				.subscribe(data => {
					this.loading = false;			
					this.crps_data = data;
					
					if (this.crps_data && this.crps_data.errMsg){
						this.err_jmbg = true;
						this.err_jmbg_poruka = this.crps_data.errMsg + '!';
						M.toast({html: this.crps_data.errMsg + '!', displayLength : 4000});
						return;
					}
					//console.log(this.crps_data);
					if (this.crps_data.ime) {					
						this.prijavakredit.ime = this.crps_data.ime;
						this.prijavakredit.prezime = this.crps_data.prezime;
						this.prijavakredit.imeRoditelja = this.crps_data.imeRoditelja;
						this.prijavakredit.opstina = this.crps_data.opstina;
						this.prijavakredit.mjestoPrebivalista = this.crps_data.mjestoPrebivalista;
						this.prijavakredit.ulicaBroj = this.crps_data.ulicaBroj;

						this.prijavakredit.drzavljanstvo = this.crps_data.drzavljanstvo;

						if (this.crps_data.datumRodjenja)
							this.prijavakredit.datumRodjenja = this.crps_data.datumRodjenja;
						
						this.prijavakredit.pol = this.crps_data.pol;
						this.prijavakredit.drzavljaninCG = '1';
						this.prijavakredit.drzava = 'Crna Gora';

						//podaci o fakultetu 
						this.prijavakredit.idFakulteta = this.crps_data.idFakulteta; 
						this.prijavakredit.godina = this.crps_data.godina; 
						this.prijavakredit.indexUspjeha = this.crps_data.indexUspjeha; 
						this.prijavakredit.brojIndeksa = this.crps_data.brojIndeksa; 
						this.prijavakredit.nivoStudija = this.crps_data.nivoStudija; 
						this.prijavakredit.redniBrojUpisa = this.crps_data.redniBrojUpisa; 
						this.prijavakredit.nazivStudProg = this.crps_data.nazivStudProg;

						if (this.crps_data.idFakulteta)
							this.fakultet_podaci_ws = true;
						else {
							//ima ga u registru drzavljana ali nema podataka o faksu. makni ucg
							//console.log(this.fakulteti);
							try {
								this.fakulteti = this.fakulteti_svi.filter((item) => !item.naziv.startsWith('UNIVERZITET CRNE GORE'));
							}
							catch(e){}
						}
						setTimeout(() => {
							M.updateTextFields();
						}, 100);

						this.err_jmbg = false;
						this.err_jmbg_poruka = '';
					}
					else {
						this.fakultet_podaci_ws = false;
						this.err_jmbg = true;
						this.err_jmbg_poruka = 'U registrima nije nađen zadati JMB!';
						M.toast({html:  'U registrima nije nađen zadati JMB!', displayLength : 4000});

						this.prijavakredit.ime = '';
						this.prijavakredit.prezime = '';
						this.prijavakredit.imeRoditelja = '';
						this.prijavakredit.datumRodjenja = '';					
						this.prijavakredit.mjestoPrebivalista = '';
						this.prijavakredit.ulicaBroj =  '';
						this.prijavakredit.pol = '';
						this.prijavakredit.drzavljanstvo = '';
						this.prijavakredit.opstina = '';
						return;
					}
				}
				, error => {
					this.loading = false;
				}
			);
		}, 500);
		/**/ 
	}

	cancel(){
		if (this.onCancel)
			this.onCancel.emit(null);
	}

	jmbgChanged(){
		this.fakultet_podaci_ws = false;
		this.prijavakredit.ime = '';
		this.prijavakredit.prezime = '';
		this.prijavakredit.imeRoditelja = '';
		this.prijavakredit.datumRodjenja = '';					
		this.prijavakredit.mjestoPrebivalista = '';
		this.prijavakredit.ulicaBroj = '';
		this.prijavakredit.pol = '';
		this.prijavakredit.opstina = '';

		this.prijavakredit.idFakulteta = ''; 
		this.prijavakredit.godina = ''; 
		this.prijavakredit.indexUspjeha = ''; 
		this.prijavakredit.brojIndeksa = ''; 
		this.prijavakredit.nivoStudija = ''; 
		this.prijavakredit.nazivStudProg = '';

		setTimeout(() => {
			M.updateTextFields();
		}, 100);
	}

	getLuca(prijava){
		if (!prijava.luca || prijava.luca == '0') return ''; 
		
		if (prijava.luca == '1') return 'A';
		if (prijava.luca == '2') return 'B';
		return prijava.luca;
	}

}
