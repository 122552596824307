import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-forget-pass-success',
	templateUrl: './forget-pass-success.component.html',
	styleUrls: ['./forget-pass-success.component.css']
})
export class ForgetPassSuccessComponent implements OnInit {

	constructor(private router: Router) {

	}

	ngOnInit() {
	}

	login(){
		this.router.navigate(["loginPage"]);
	}

}
