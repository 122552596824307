import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { ForgetpassComponent } from './forgetpass/forgetpass.component';
import { ForgetPassSuccessComponent } from './forget-pass-success/forget-pass-success.component';

const routes: Routes = [
  
  { path: 'loginPage', component: LoginComponent},
  { path: 'homePage', component: HomeComponent},
  { path: 'register', component: RegisterComponent},
  { path: 'register-success', component: RegisterSuccessComponent},
  { path: 'forgotten-pass', component: ForgetpassComponent},
  { path: 'forgotten-pass-success', component: ForgetPassSuccessComponent},
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { useHash: true }) ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
