import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as M from "materialize-css/dist/js/materialize";
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';

@Component({
	selector: 'app-forgetpass',
	templateUrl: './forgetpass.component.html',
	styleUrls: ['./forgetpass.component.css']
})
export class ForgetpassComponent implements OnInit {

	constructor(private router :  Router, private http : HttpClient, public globalSrvc : GlobalService) { }

	user: any = { mail: ''};
	saving : boolean = false;
	err_mail = false;
	err_mail_msg = '';
	badLogin = false;
	badLoginMsg : any = '';
	msg : any = null;

	ngOnInit() {
	}

	login(){
		this.router.navigate(["loginPage"]);
	}

	sendpass(){	
		if (!this.user.mail){
			this.err_mail = true;
			this.err_mail_msg = 'Mail adresa ne smije biti prazna';
			M.toast({html: 'Mail adresa ne smije biti prazna', displayLength : 2000});
			return;
		}

		if (!this.checkEmail(this.user.mail)){
			this.err_mail = true;
			this.err_mail_msg = 'Nevalidan format za mail adresu';
			M.toast({html: 'Nevalidan format za mail adresu', displayLength : 2000});
			return;
		}

		this.err_mail = false;
		this.err_mail_msg = '';

		this.saving = true;
		setTimeout(() => {
			this.http.post(this.globalSrvc.rootUrl +'sendpass', this.user)
			.subscribe(data => {
				this.msg = data;
				this.saving = false;

				if(this.msg.id == 1){
					this.router.navigate(["forgotten-pass-success"]);
				}
				else{
					this.badLogin = true;
					this.badLoginMsg = this.msg.text;
					M.toast({html: this.badLoginMsg, displayLength : 2000});
				}
			}
			,error => {
				this.saving = false;				
			});
		}, 500);
	}

	checkEmail = (input) => {
		let regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    	return regexp.test(input.toLowerCase());
	}
}
