import { Component, OnInit, ViewEncapsulation,ViewChild,Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from '../global.service';
import * as M from "materialize-css/dist/js/materialize";
import * as $ from 'jquery';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpParams } from '@angular/common/http';
/*import { forkJoin } from 'rxjs/observable/forkJoin';*/
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Component({
	selector: 'ugovor-studiranje',
	templateUrl: './ugovor-studiranje.component.html',
	styleUrls: ['./ugovor-studiranje.component.css']
})
export class UgovorStudiranjeComponent implements OnInit {
	@Input() id;
	@Output() onInsertUspjeh = new EventEmitter<any>();
	@Output() onCancel = new EventEmitter<any>();

	@ViewChild('file', {static : null}) file;
	public files: Set<File> = new Set();
	public sel_file: File = null;
	
	msg : any = null;
	serverMsg : any = null; 
	loading : any = false; 
	saveDisabled = false;
	progress : any;
	progressPct : any;
    canBeClosed = true;
    primaryButtonText = '';
    showCancelButton = true;
    uploading = false;
	uploadSuccessful = false;
	kljucniakti : any = {'rbr' : '', 'idUstanove' : '', 'tipDokumenta' : '', 'opis' : '', 'brojZavodjenja' : '', 'datumOd' : '', 'datumDo' : '', 'dokument' : ''};
	prctDone : any = null; 
	jePotpisan :any = false; 

	constructor(private http: HttpClient, public globalSrvc: GlobalService) { }

	ngOnInit() {
		window.scroll(0, 0);
		this.getugovorjepotpisan();
	}

	cancel(){
		if (this.onCancel)
			this.onCancel.emit(null);
	}

	ugovorData : any; 

	getugovor(){
		this.loading = true;
        let url = 'ugovor';
        
        
        this.http.get(url, {params : {'idPrijave' : this.id}, responseType: 'blob'})
        .subscribe(
         data => { 			
            this.loading = false;
			//console.log(data);
			if (!data || data.size == 0)
				M.toast({html: 'Nije mоguće generisati ugovor. Provjerite da li ste dobili studentski kod.', displayLength : 4000});
			else 
            	this.globalSrvc.downloadPdf(data, 'ugovorPdf');
			//this.globalSrvc.openBlob(data, "word");
         },
         error => {            
            this.loading = false;
            window.alert('Došlo je do greške prilikom generisanja ugovora');
        }); 
	}

	getugovorjepotpisan(){
		this.loading = true;
        let url = 'ugovor/jepotpisan';
        
        
        this.http.get(url, {params : {'idPrijave' : this.id}})
        .subscribe(
         data => {            
            this.loading = false;
			this.jePotpisan = data;
			//console.log(this.jePotpisan);
            //this.globalSrvc.downloadPdf(data, 'ugovorPdf');
			//this.globalSrvc.openBlob(data, "word");

         },
         error => {            
            this.loading = false;
            window.alert('Došlo je do greške prilikom generisanja ugovora');
        }); 
	}

	getugovorFinal(){
		this.loading = true;
        let url = 'ugovor/get';
        
        
        this.http.get(url, {params : {'idPrijave' : this.id}, responseType: 'blob'})
        .subscribe(
         data => {            
            this.loading = false;
            this.globalSrvc.downloadPdf(data, 'ugovorPdf');
			//this.globalSrvc.openBlob(data, "word");
         },
         error => {            
            this.loading = false;
            window.alert('Došlo je do greške prilikom generisanja ugovora');
        }); 
	}

	onFilesAdded() {
		console.log('odabir fajla');
		this.files.clear();
		this.sel_file = null;
        const files: { [key: string]: File } = this.file.nativeElement.files;
        for (let key in files) {
            if (!isNaN(parseInt(key))) {
				this.files.add(files[key]);
				if (!this.sel_file){
					this.kljucniakti.dokument = files[key].name;
					this.sel_file = files[key];
				}
				//console.log('size', files[key]);
            }
        }
        this.progress = this.uploadFile();
	}
	
	addFiles() {
        this.file.nativeElement.click();
	}

	uploadFile(): Observable<number> {
		// this will be the our resulting map
		//console.log(this.sel_file);
		if (!this.sel_file) 
			return null;
		
		this.uploading = true;
		this.saveDisabled = true;
		
		const status = {};

		const formData: FormData = new FormData();
		formData.append('file', this.sel_file, this.sel_file.name);

		// create a http-post request and pass the form
		// tell it to report the upload progress
		const req = new HttpRequest('POST', 'ugovor/upload?idPrijave=' + this.id, formData, {reportProgress: true});

		// create a new progress-subject for every file
		const progress = new Subject<number>();
		
		
		// send the http-request and subscribe for progress-updates
		this.http.request(req).subscribe(event => {
			
			if (event.type === HttpEventType.UploadProgress) {
				const percentDone = Math.round(100 * event.loaded / event.total);  
				progress.next(percentDone);
				this.prctDone = percentDone;
			} 
			else if (event instanceof HttpResponse) {
				progress.complete();
				this.uploading = false;
				this.saveDisabled = true;
				this.msg = event.body;
				this.kljucniakti.dokument = this.msg.text;
				console.log(this.file);
				this.file.nativeElement.value = null;
				/*if (this.msg.id == 1)
					this.saveToDb();*/
				if (this.msg.id == 2){
					this.serverMsg = this.msg.text;
					var elem = document.getElementById('modal_ugovor_2');				
					var instance = M.Modal.init(elem, {});
					instance.open();	
				}
				else {
					var elem = document.getElementById('modal_ugovor_1');				
					var instance = M.Modal.init(elem, {});
					instance.open();
					this.jePotpisan = true;
				}
			}
		}
		,error => {
			this.uploading = false;
			this.saveDisabled = true;
			//this.globalSrvc.showErrorModal('Došlo je do greške prilikom uploada fajla');
			window.alert('Došlo je do greške prilikom uploada fajla');
		});

		// Save every progress-observable in a map of all observables
		return progress.asObservable();
	}

	getProgressStyle(){
		//console.log(this.progress);
		//if (!this.progress) return '';
		//let result = "width: " + (this.progressPct) + "%";
		let result = "width: 60%";
		console.log(result); 
		return result;
	}

	openhelp(){
		
        let url = 'upustvougovor';
        this.http.get(url, {params : {}, responseType: 'blob'})
        .subscribe(
         data => {            
            this.loading = false;
            this.globalSrvc.openBlob(data, 'pdf');
         },
         error => {            
            this.loading = false;
            window.alert('Došlo je do greške prilikom fajla');
        });   
	}
}
