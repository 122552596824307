import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import * as M from "materialize-css/dist/js/materialize";

@Component({
	selector: 'rangiranje-dom',
	templateUrl: './rangiranje-dom.component.html',
	styleUrls: ['./rangiranje-dom.component.css']
})
export class RangiranjeDomComponent implements OnInit {
	@Input() id;
	@Output() onInsertUspjeh = new EventEmitter<any>();
	@Output() onCancel = new EventEmitter<any>();
    loading = false;
	msg : any;
	prvi_predmet = '';
	drugi_predmet = '';
	novi = true;
    uspjeh : any = {'predmet31' : '', 'predmet32' : '', 'predmet41' : '', 'predmet42' : ''};
	bodovi : any = [];
	rang_lista_dom : any = {upsjeh : this.uspjeh, kandidati : []};
	intevalId : any = null;
	refreshInterval = 1 * 600 * 1000;
	pozicija = 1;
	idTipaPrijave : any = null;

	constructor(private http: HttpClient, public globalSrvc: GlobalService) { }

    ngOnInit() {
		console.log(this.idTipaPrijave);

		scroll(0,0);
        this.ucitaj(true);

		setTimeout(() => {
			var elems = document.querySelectorAll('.tooltipped');
			var instances = M.Tooltip.init(elems, {});
		}, 1000);

		this.intevalId = setInterval(
			() => {
				this.ucitaj(false); 
			}, this.refreshInterval);
    }
	
	ngOnDestroy() {
		if (this.intevalId) {
			clearInterval(this.intevalId);
		}
	}

    ucitaj(setLoading){
		let ts_ucitavanje_dom = localStorage.getItem('ts_ucitavanje_dom' + this.id);
		if (ts_ucitavanje_dom){
			let intVal = parseInt(ts_ucitavanje_dom);
			
       		if (Number.isInteger(intVal)){
				let diff = (new Date()).getTime() - intVal;
				if (diff < 20000){
					let server_data = localStorage.getItem('rang_lista_dom' + this.id);
					if (server_data){
						this.rang_lista_dom = JSON.parse(server_data);
						this.uspjeh = this.rang_lista_dom.uspjeh; 
						this.pozicija = this.rang_lista_dom.pozicija;
						this.bodovi = [];
						this.bodovi.push(this.uspjeh);
						return;
					}
				}
			}
		}
		if (setLoading)
			this.loading = true;

		this.http.get(this.globalSrvc.rootUrl +'prijave/dom/rang/', { params: { id: this.id} })
		.subscribe(data => {
			localStorage.setItem('ts_ucitavanje_dom' + this.id, (new Date()).getTime() + '');
			localStorage.setItem('rang_lista_dom' + this.id, JSON.stringify(data));
			//console.log(data);
			this.loading = false;
			this.rang_lista_dom = data;
			this.uspjeh = this.rang_lista_dom.uspjeh; 
			this.pozicija = this.rang_lista_dom.pozicija;
			this.idTipaPrijave = this.rang_lista_dom.idTipaPrijave;
            console.log("uspjeh", this.rang_lista_dom);
			this.bodovi = [];
			this.bodovi.push(this.uspjeh);
		}
		,error => {
			this.loading = false;
		});
	}

	toastTimeout = 3000;

	cancel(){
		if (this.onCancel)
		
		
		this.onCancel.emit(null);
	}

	getBodoviLuca(luca){
		if (!luca)
		 	return '';
		
		if (luca == 1)
		 	return 10;
		if (luca == 2)
			return 5;

		return '';
	}

	getStepenStudija(stepen){
		if (!stepen)
			return '';

		if (stepen == 'O')
			return 'Osnovne';

		if (stepen == 'M')
			return 'Master';
		
		return '';
		
	}
	
	getOcjenaExterna(extocjena){
		if (!extocjena)
		 	return '';
		
		return extocjena;
	}

	getBodoviTakmicenja(bodovi){
		if (bodovi === 0)
			return '';
		else 
			return bodovi;
	}
	
	getOcjena(ocjena){
		if (!ocjena)
			return '';
		else 
			return ocjena;
	}

	jeSrednja(){
		if (this.idTipaPrijave == '1' || this.idTipaPrijave == '2')
			return true; 
		else 
			return false; 
	}

	getBodoviPorStatus(value){
		if (value == '1') return '5';
		else return '';
	}

	
}
