import { Component, OnInit, ViewEncapsulation,ViewChild,Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import * as M from "materialize-css/dist/js/materialize";
import * as $ from 'jquery';

class SvjImage{
	base64 : any;
	file : any; 
	type : string;  
}

@Component({
	selector: 'unos-izmjena-dokumenta-prvi-srednje',
	templateUrl: './unos-izmjena-dokumenta-prvi-srednje.component.html',
	styleUrls: ['./unos-izmjena-dokumenta-prvi-srednje.component.css'], 
	encapsulation: ViewEncapsulation.None 
})
export class UnosIzmjenaDokumentaPrviSrednjeComponent implements OnInit {
	@Input() id;
	@Output() onInsertUspjeh = new EventEmitter<any>();
	@Output() onCancel = new EventEmitter<any>();

	prijavaskola : any = {'id' : '', 'smjerId' : '', 'idOblasti' : '', 'ocjenaMaternji7' : '', 'ocjenaMaternji8' : '', 'ocjenaMaternji9' : '', 'ocjenaMatematika7' : '', 'ocjenaMatematika8' : '', 'ocjenaMatematika9' : '', 'ocjenaPrvi7' : '', 'ocjenaPrvi8' : '', 'ocjenaPrvi9' : '', 'ocjenaDrugi7' : '', 'ocjenaDrugi8' : '', 'ocjenaDrugi9' : '', 'uspjeh7' : '', 'uspjeh8' : '', 'uspjeh9' : '', 'ocjenaExtMaternji' : '', 'ocjenaExtMatematika' : '', 'ocjenaExtIII' : '', 'idGrupeExtIII' : '','nazivGrupeExtIII' : '', 'bodoviTakmicenje' : '', 'luca' : '', 'potpuniPodaci' : '1', 'dodatnaNapomena' : '', 'predmeti' : [], 'takmicenja' : []};

	@ViewChild('svj_9_lice', null) svj_9_liceFile;
	svj9_lice : SvjImage = new SvjImage();
	prijava = {svj_9_lice_slika : '', svj_9_lice_slika_tip : ''};
	svj_9_lice_base64 : any; 

	predmeti : any = [];
	err_ocjenaMaternji7 = false;
	err_ocjenaMaternji7_poruka = '';
	ocjene : any = [{'id' : '1', 'naziv' : '1', jeOdabran : false},{'id' : '2', 'naziv' : '2', jeOdabran : false},{'id' : '3', 'naziv' : '3', jeOdabran : false},{'id' : '4', 'naziv' : '4', jeOdabran : false},{'id' : '5', 'naziv' : '5', jeOdabran : false}];
	err_ocjenaMaternji8 = false;
	err_ocjenaMaternji8_poruka = '';
	err_ocjenaMaternji9 = false;
	err_ocjenaMaternji9_poruka = '';
	err_ocjenaPrvi7 = false;
	err_ocjenaPrvi7_poruka = '';
	err_ocjenaPrvi8 = false;
	err_ocjenaPrvi8_poruka = '';
	err_ocjenaPrvi9 = false;
	err_ocjenaPrvi9_poruka = '';
	err_ocjenaDrugi7 = false;
	err_ocjenaDrugi7_poruka = '';
	err_ocjenaDrugi8 = false;
	err_ocjenaDrugi8_poruka = '';
	err_ocjenaDrugi9 = false;
	err_ocjenaDrugi9_poruka = '';
	err_uspjeh7 = false;
	err_uspjeh7_poruka = '';
	uspjeh : any = [{'id' : '1', 'naziv' : 'Nedovoljan', jeOdabran : false},{'id' : '2', 'naziv' : 'Dovoljan', jeOdabran : false},{'id' : '3', 'naziv' : 'Dobar', jeOdabran : false},{'id' : '4', 'naziv' : 'Vrlo dobar', jeOdabran : false},{'id' : '5', 'naziv' : 'Odličan', jeOdabran : false}];
	err_uspjeh8 = false;
	err_uspjeh8_poruka = '';
	err_uspjeh9 = false;
	err_uspjeh9_poruka = '';
	err_luca = false;
	err_luca_poruka = '';
	luce : any = [{'id' : '1', 'naziv' : 'Da', jeOdabran : false},{'id' : '0', 'naziv' : 'Ne', jeOdabran : false}];
	saveDisabled = false;
	loading = false;
	msg : any;
	prvi_predmet = '';
	drugi_predmet = '';
	novi = true;

	constructor(private http: HttpClient, public globalSrvc: GlobalService) { }

	ngOnInit() {
		this.ucitaj();
	}
	//napraviti univerzalnu funkciju predajom parametera 
	handleSvj9LiceFileChange = (viewchild, imageData : SvjImage) => {
		console.log(viewchild,  imageData);

		let files = viewchild.nativeElement.files;

		if (FileReader && files && files.length) {
	        let fr = new FileReader();
	        fr.onload = () => {
				imageData.base64 = fr.result;
				console.log(imageData);
			}
			console.log(files[0]);
			if (!files || !files[0] || !files[0].type){
				M.toast({html: 'Nedozvoljen tip slike. Dozvoljeni su PNG i JPG formati slike!', displayLength : 4000});
				return;
			}

			if (files[0].type != 'image/png' && files[0].type != 'image/jpg' && files[0].type != 'image/jpeg'){
				M.toast({html: 'Nedozvoljen tip slike. Dozvoljeni su PNG i JPG formati slike!', displayLength : 4000});
				return;
			}
			imageData.type = files[0].type;
			imageData.file = files[0].name;
	        fr.readAsDataURL(files[0]);
	    }
	}

	odaberiSvj9Lice(){
		this.svj_9_liceFile.nativeElement.click();
	}

	ucitaj(){
		this.loading = true;
		this.http.get(this.globalSrvc.rootUrl +'prijave/srednja/uspjeh/', { params: { id: this.id } })
		.subscribe(data => {
			this.loading = false;
			this.prijavaskola = data;
			//console.log(this.prijavaskola);
			this.predmeti = this.prijavaskola.predmeti;
			if (this.predmeti && this.predmeti.length > 1){
				this.prvi_predmet = this.predmeti[0].naziv;
				this.drugi_predmet = this.predmeti[1].naziv;
			}
			//if(this.prijavaskola.uspjehDef == '1')
			//	this.novi = false;
		}
		,error => {
			this.loading = false;
		});
	}

	toastTimeout = 3000;

	savedata(){
		/*if (this.prijavaskola.ocjenaMaternji7 === undefined || this.prijavaskola.ocjenaMaternji7 == null || this.prijavaskola.ocjenaMaternji7 == ''){
			this.err_ocjenaMaternji7 = true;
			this.err_ocjenaMaternji7_poruka = 'Morate definisati polje Ocjena maternji (7. razred)';
			M.toast({html: this.err_ocjenaMaternji7_poruka, displayLength : this.toastTimeout});
			return;
		}
		this.err_ocjenaMaternji7 = false;
		this.err_ocjenaMaternji7_poruka = '';

		if (this.prijavaskola.ocjenaMaternji8 === undefined || this.prijavaskola.ocjenaMaternji8 == null || this.prijavaskola.ocjenaMaternji8 == ''){
			this.err_ocjenaMaternji8 = true;
			this.err_ocjenaMaternji8_poruka = 'Morate definisati polje Ocjena maternji (8. razred)';
			M.toast({html: this.err_ocjenaMaternji8_poruka, displayLength : this.toastTimeout});
			return;
		}
		this.err_ocjenaMaternji8 = false;
		this.err_ocjenaMaternji8_poruka = '';

		if (this.prijavaskola.ocjenaMaternji9 === undefined || this.prijavaskola.ocjenaMaternji9 == null || this.prijavaskola.ocjenaMaternji9 == ''){
			this.err_ocjenaMaternji9 = true;
			this.err_ocjenaMaternji9_poruka = 'Morate definisati polje Ocjena maternji (9. razred)';
			M.toast({html: this.err_ocjenaMaternji9_poruka, displayLength : this.toastTimeout});
			return;
		}
		this.err_ocjenaMaternji9 = false;
		this.err_ocjenaMaternji9_poruka = '';

		if (this.prijavaskola.ocjenaPrvi7 === undefined || this.prijavaskola.ocjenaPrvi7 == null || this.prijavaskola.ocjenaPrvi7 == ''){
			this.err_ocjenaPrvi7 = true;
			this.err_ocjenaPrvi7_poruka = 'Morate definisati polje Ocjena ' + this.prvi_predmet + ' (7. razred)';
			M.toast({html: this.err_ocjenaPrvi7_poruka, displayLength : this.toastTimeout});
			return;
		}
		this.err_ocjenaPrvi7 = false;
		this.err_ocjenaPrvi7_poruka = '';

		if (this.prijavaskola.ocjenaPrvi8 === undefined || this.prijavaskola.ocjenaPrvi8 == null || this.prijavaskola.ocjenaPrvi8 == ''){
			this.err_ocjenaPrvi8 = true;
			this.err_ocjenaPrvi8_poruka = 'Morate definisati polje Ocjena ' + this.prvi_predmet + ' (8. razred)';
			M.toast({html: this.err_ocjenaPrvi8_poruka, displayLength : this.toastTimeout});
			return;
		}
		this.err_ocjenaPrvi8 = false;
		this.err_ocjenaPrvi8_poruka = '';

		if (this.prijavaskola.ocjenaPrvi9 === undefined || this.prijavaskola.ocjenaPrvi9 == null || this.prijavaskola.ocjenaPrvi9 == ''){
			this.err_ocjenaPrvi9 = true;
			this.err_ocjenaPrvi9_poruka = 'Morate definisati polje Ocjena ' + this.prvi_predmet + ' (9. razred)';
			M.toast({html: this.err_ocjenaPrvi9_poruka, displayLength : this.toastTimeout});
			return;
		}
		this.err_ocjenaPrvi9 = false;
		this.err_ocjenaPrvi9_poruka = '';

		if (this.prijavaskola.ocjenaDrugi7 === undefined || this.prijavaskola.ocjenaDrugi7 == null || this.prijavaskola.ocjenaDrugi7 == ''){
			this.err_ocjenaDrugi7 = true;
			this.err_ocjenaDrugi7_poruka = 'Morate definisati polje Ocjena ' + this.drugi_predmet + ' (7. razred)';
			M.toast({html: this.err_ocjenaDrugi7_poruka, displayLength : this.toastTimeout});
			return;
		}
		this.err_ocjenaDrugi7 = false;
		this.err_ocjenaDrugi7_poruka = '';

		if (this.prijavaskola.ocjenaDrugi8 === undefined || this.prijavaskola.ocjenaDrugi8 == null || this.prijavaskola.ocjenaDrugi8 == ''){
			this.err_ocjenaDrugi8 = true;
			this.err_ocjenaDrugi8_poruka = 'Morate definisati polje Ocjena ' + this.drugi_predmet + ' (8. razred)';
			M.toast({html: this.err_ocjenaDrugi8_poruka, displayLength : this.toastTimeout});
			return;
		}
		this.err_ocjenaDrugi8 = false;
		this.err_ocjenaDrugi8_poruka = '';

		if (this.prijavaskola.ocjenaDrugi9 === undefined || this.prijavaskola.ocjenaDrugi9 == null || this.prijavaskola.ocjenaDrugi9 == ''){
			this.err_ocjenaDrugi9 = true;
			this.err_ocjenaDrugi9_poruka = 'Morate definisati polje Ocjena ' + this.drugi_predmet + ' (9. razred)';
			M.toast({html: this.err_ocjenaDrugi9_poruka, displayLength : this.toastTimeout});
			return;
		}
		this.err_ocjenaDrugi9 = false;
		this.err_ocjenaDrugi9_poruka = '';

		if (this.prijavaskola.uspjeh7 === undefined || this.prijavaskola.uspjeh7 == null || this.prijavaskola.uspjeh7 == ''){
			this.err_uspjeh7 = true;
			this.err_uspjeh7_poruka = 'Morate definisati polje Uspjeh (7. razred)';
			return;
		}
		this.err_uspjeh7 = false;
		this.err_uspjeh7_poruka = '';

		if (!(this.prijavaskola.uspjeh7 === undefined || this.prijavaskola.uspjeh7 == null || this.prijavaskola.uspjeh7 == '')){
			if (!this.globalSrvc.CheckNumber(this.prijavaskola.uspjeh7)){
				this.err_uspjeh7 = true;
				this.err_uspjeh7_poruka = 'Nevalidan format za polje Uspjeh (7. razred)';
				return;
			}
		}
		this.err_uspjeh7 = false;
		this.err_uspjeh7_poruka = '';

		if (this.prijavaskola.uspjeh8 === undefined || this.prijavaskola.uspjeh8 == null || this.prijavaskola.uspjeh8 == ''){
			this.err_uspjeh8 = true;
			this.err_uspjeh8_poruka = 'Morate definisati polje Uspjeh (8. razred)';
			return;
		}
		this.err_uspjeh8 = false;
		this.err_uspjeh8_poruka = '';

		if (!(this.prijavaskola.uspjeh8 === undefined || this.prijavaskola.uspjeh8 == null || this.prijavaskola.uspjeh8 == '')){
			if (!this.globalSrvc.CheckNumber(this.prijavaskola.uspjeh8)){
				this.err_uspjeh8 = true;
				this.err_uspjeh8_poruka = 'Nevalidan format za polje Uspjeh (8. razred)';
				return;
			}
		}
		this.err_uspjeh8 = false;
		this.err_uspjeh8_poruka = '';

		if (this.prijavaskola.uspjeh9 === undefined || this.prijavaskola.uspjeh9 == null || this.prijavaskola.uspjeh9 == ''){
			this.err_uspjeh9 = true;
			this.err_uspjeh9_poruka = 'Morate definisati polje Uspjeh (9. razred)';
			return;
		}
		this.err_uspjeh9 = false;
		this.err_uspjeh9_poruka = '';

		if (!(this.prijavaskola.uspjeh9 === undefined || this.prijavaskola.uspjeh9 == null || this.prijavaskola.uspjeh9 == '')){
			if (!this.globalSrvc.CheckNumber(this.prijavaskola.uspjeh9)){
				this.err_uspjeh9 = true;
				this.err_uspjeh9_poruka = 'Nevalidan format za polje Uspjeh (9. razred)';
				return;
			}
		}
		this.err_uspjeh9 = false;
		this.err_uspjeh9_poruka = '';

		if (this.prijavaskola.luca === undefined || this.prijavaskola.luca == null || this.prijavaskola.luca == ''){
			this.err_luca = true;
			this.err_luca_poruka = 'Morate definisati polje Luča';
			return;
		}
		this.err_luca = false;
		this.err_luca_poruka = '';
		*/

		let url = 'prijave/srednja/uspjeh/izmijeni';
		this.saveDisabled=true;
		this.prijavaskola.id = this.id; 

		this.loading = true;

			//this.prijavaskola.datumRodjenja = this.globalSrvc.NGBStructToDate(this.datumRodjenja);
			//this.prijavaskola.brojJedinice = this.jedinica.broj;

		setTimeout(() => {				
		
			this.http.post(url, this.prijavaskola)
				.subscribe(data => {
					this.msg = data;
					this.loading = false;

					if (this.msg.id == 1) {
						if (this.prijavaskola.id === undefined || this.prijavaskola.id == null || this.prijavaskola.id == '') {
							this.prijavaskola.id = this.msg.newId;
						}
						//this.activeModal.close(this.prijavaskola);
						//this.globalSrvc.showSuccessModal();
						//M.toast({html : 'Uspješno', displayLength : this.toastTimeout});

						if (this.onInsertUspjeh)
							this.onInsertUspjeh.emit(this.prijavaskola);
					}
					else {
						//this.globalSrvc.showErrorModal(this.msg.text);
						M.toast({html:this.msg.text, displayLength : this.toastTimeout});
					}
				}
				, error => {
					this.loading = false;
					//this.globalSrvc.showGenericErrorModal();
				}
			);
		}, 1000);	
	}

	cancel(){
		if (this.onCancel)
			this.onCancel.emit(null);
	}

	getLabelStyle(data){
		if (!data)
			return {"color" : "red"};
		else 
			return {};
	}

	getNazivMaternji(){
		if (this.prijavaskola.idMaternji == '5')
			return 'CSBH';
		
		if (this.prijavaskola.idMaternji == '2000')
			return 'Albanski jezik i književnost';

		return "Maternji jezik";
	}
}
