import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { GlobalService } from './global.service';
import { tap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { catchError } from "rxjs/internal/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private globalSrvc: GlobalService) { }

    /*private getXsrfCookie(): string {
        console.log('cookie');
        var matches = document.cookie.match(/\bXSRF-TOKEN=([^\s;]+)/);
        try {
            return (matches && decodeURIComponent(matches[1]));
        } catch (decodeError) {
            return ("");
        }
    }*/

    isPermitPath(path) : boolean{
        if (path == 'authenticate' || path == 'register' || path == 'confirm_user')
            return true;
        else 
            return false;
    }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //var xsrfToken = this.getXsrfCookie();
        //localStorage.setItem("acmetoken", '');
        let xReq = req;
        let idToken = localStorage.getItem("acmetoken");
        
        if (idToken && !this.isPermitPath(req.url)) {
            xReq = req.clone({ headers: req.headers.set("Authorization", "Bearer " + idToken) });
        }

        return next.handle(xReq).pipe(
            catchError(
                (error: any, caught: Observable<HttpEvent<any>>) => {
                    if (error.status === 401 || error.status == 403) {
                        //console.log('401, redirekcija na app', error);
                        this.globalSrvc.logout();
                        this.globalSrvc.redirectToLogin();
                        throw error;
                    }
                    return of(error);
                }
            ));
    }

}