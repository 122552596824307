import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as M from "materialize-css/dist/js/materialize";
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	user: any = { username: '', password: ''};
	public loading : boolean = false;
    public badLogin : boolean = false;
	public badLoginMsg : string = '';
	
	constructor(private router :  Router, private http : HttpClient, public globalSrvc : GlobalService) { }

	ngOnInit() {
	}

	register(){
		this.router.navigate(["register"]);
	}

	forgetpass(){
		this.router.navigate(["forgotten-pass"]);
	}

	login(){
		let server_data : any = '';
        this.loading = true;
		
		setTimeout(() => {
			this.http.post(this.globalSrvc.rootUrl + 'authenticate', this.user)
			.subscribe(data => {
				server_data = data;
				this.loading = false;
				//console.log('server data', server_data);
				if (server_data.token){
					//uspjesna registracija, redirekt se na home stranicu korisnika
					//store token 
					localStorage.setItem('acmetoken', server_data.token);
					this.globalSrvc.credentials.username = this.user.username;
					this.globalSrvc.isLogged = true;
					//this.globalSrvc.isAdmin = server_data.is_admin;
					this.badLogin = false;
					this.badLoginMsg = '';
					this.router.navigate(["homePage"]);
				}
				else {
					this.badLogin = true;
					this.badLoginMsg = server_data.errMsg;
					this.globalSrvc.credentials.username = '';
					this.globalSrvc.isLogged = false;
				}
			}
			,error => {
				this.loading = false;
				this.badLogin = true;
				this.badLoginMsg = 'Došlo je do greške prilikom registracije';
				this.globalSrvc.isLogged = false;
			});
		}, 1000);
	}

	resendlink(){
		let server_data : any = '';
        this.loading = true;
		let data = {mail : this.user.username};

		setTimeout(() => {
			this.http.post(this.globalSrvc.rootUrl + 'resend_acc_link', data)
			.subscribe(data => {
				server_data = data;
				this.loading = false;
				//console.log('server data', server_data);
				if (server_data){
					//uspjesna registracija, redirekt se na home stranicu korisnika
					this.badLogin = true;
					this.badLoginMsg = server_data.errMsg;
					//this.globalSrvc.credentials.username = '';
					this.globalSrvc.isLogged = false;
				}
				else {
					this.badLogin = false;
					this.badLoginMsg = '';
					this.router.navigate(["register-success"]);	
				}
			}
			,error => {
				this.loading = false;
				this.badLogin = true;
				this.badLoginMsg = 'Došlo je do greške prilikom registracije';
				this.globalSrvc.isLogged = false;
			});
		}, 1000);
	}

	openhelp(){
        window.open('https://www.youtube.com/channel/UCHXRp4Jsidh30uMxsYn-TIA');
    }
}
